import { IThunkStatus } from '@app-types';
import { IWebRTCConfigModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createWebRTCConfigAction,
  deleteWebRTCConfigAction,
  editWebRTCConfigAction,
  getWebRTCConfigsAction,
} from '../actions/telephonyConfigActions';

interface IWebRTCConfigState {
  createWebRTCConfigStatus: IThunkStatus;
  createWebRTCConfigSuccess: string;
  createWebRTCConfigError: string;

  editWebRTCConfigStatus: IThunkStatus;
  editWebRTCConfigSuccess: string;
  editWebRTCConfigError: string;

  deleteWebRTCConfigStatus: IThunkStatus;
  deleteWebRTCConfigSuccess: string;
  deleteWebRTCConfigError: string;

  getWebRTCConfigsStatus: IThunkStatus;
  getWebRTCConfigsSuccess: string;
  getWebRTCConfigsError: string;

  telephonyConfig: IWebRTCConfigModel | null;
  telephonyConfigs: IWebRTCConfigModel[];
}

const initialState: IWebRTCConfigState = {
  deleteWebRTCConfigError: '',
  deleteWebRTCConfigStatus: 'idle',
  deleteWebRTCConfigSuccess: '',
  editWebRTCConfigError: '',
  editWebRTCConfigStatus: 'idle',
  editWebRTCConfigSuccess: '',
  getWebRTCConfigsError: '',
  getWebRTCConfigsStatus: 'idle',
  getWebRTCConfigsSuccess: '',
  createWebRTCConfigStatus: 'idle',
  createWebRTCConfigSuccess: '',
  createWebRTCConfigError: '',

  telephonyConfig: null,
  telephonyConfigs: [],
};

const telephonyConfigSlice = createSlice({
  name: 'telephony:settings',
  initialState,
  reducers: {
    resetCreateWebRTCConfigStatus(state: IWebRTCConfigState) {
      state.createWebRTCConfigStatus = 'idle';
      state.createWebRTCConfigSuccess = '';
      state.createWebRTCConfigSuccess = '';
    },
    resetEditWebRTCConfigStatus(state: IWebRTCConfigState) {
      state.editWebRTCConfigStatus = 'idle';
      state.editWebRTCConfigSuccess = '';
      state.editWebRTCConfigSuccess = '';
    },
    resetDeleteWebRTCConfigStatus(state: IWebRTCConfigState) {
      state.deleteWebRTCConfigStatus = 'idle';
      state.deleteWebRTCConfigSuccess = '';
      state.deleteWebRTCConfigSuccess = '';
    },

    resetGetWebRTCConfigsStatus(state: IWebRTCConfigState) {
      state.getWebRTCConfigsStatus = 'idle';
      state.getWebRTCConfigsSuccess = '';
      state.getWebRTCConfigsSuccess = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createWebRTCConfigAction.pending, state => {
        state.createWebRTCConfigStatus = 'loading';
      })
      .addCase(createWebRTCConfigAction.fulfilled, (state, action) => {
        state.createWebRTCConfigStatus = 'completed';
        state.createWebRTCConfigSuccess = action.payload.message;

        if (action.payload.result) {
          state.telephonyConfig = action.payload.result;

          state.telephonyConfigs.push(action.payload.result);
        }
      })
      .addCase(createWebRTCConfigAction.rejected, (state, action) => {
        state.createWebRTCConfigStatus = 'failed';

        if (action.payload) {
          state.createWebRTCConfigError = action.payload.message;
        } else state.createWebRTCConfigError = action.error.message as string;
      });

    builder
      .addCase(editWebRTCConfigAction.pending, state => {
        state.editWebRTCConfigStatus = 'loading';
      })
      .addCase(editWebRTCConfigAction.fulfilled, (state, action) => {
        state.editWebRTCConfigStatus = 'completed';
        state.editWebRTCConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.telephonyConfigs];

          state.telephonyConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.telephonyConfigs.splice(index, 1, config);
          }
        }
      })
      .addCase(editWebRTCConfigAction.rejected, (state, action) => {
        state.editWebRTCConfigStatus = 'failed';

        if (action.payload) {
          state.editWebRTCConfigError = action.payload.message;
        } else state.editWebRTCConfigError = action.error.message as string;
      });

    builder
      .addCase(deleteWebRTCConfigAction.pending, state => {
        state.deleteWebRTCConfigStatus = 'loading';
      })
      .addCase(deleteWebRTCConfigAction.fulfilled, (state, action) => {
        state.deleteWebRTCConfigStatus = 'completed';
        state.deleteWebRTCConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.telephonyConfigs];

          state.telephonyConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.telephonyConfigs.splice(index, 1);
          }
        }
      })
      .addCase(deleteWebRTCConfigAction.rejected, (state, action) => {
        state.deleteWebRTCConfigStatus = 'failed';

        if (action.payload) {
          state.deleteWebRTCConfigError = action.payload.message;
        } else state.deleteWebRTCConfigError = action.error.message as string;
      });

    builder
      .addCase(getWebRTCConfigsAction.pending, state => {
        state.deleteWebRTCConfigStatus = 'loading';
      })
      .addCase(getWebRTCConfigsAction.fulfilled, (state, action) => {
        state.getWebRTCConfigsStatus = 'completed';
        state.getWebRTCConfigsSuccess = action.payload.message;
        state.telephonyConfigs = action.payload.results as IWebRTCConfigModel[];
      })
      .addCase(getWebRTCConfigsAction.rejected, (state, action) => {
        state.getWebRTCConfigsStatus = 'failed';

        if (action.payload) {
          state.getWebRTCConfigsError = action.payload.message;
        } else state.getWebRTCConfigsError = action.error.message as string;
      });
  },
});

export const {
  resetCreateWebRTCConfigStatus,
  resetGetWebRTCConfigsStatus,
  resetEditWebRTCConfigStatus,
  resetDeleteWebRTCConfigStatus,
} = telephonyConfigSlice.actions;
export default telephonyConfigSlice.reducer;
