import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

import './utils/highlight';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import initFacebookSdk from './utils/initFacebookSdk';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initFacebookSdk()
  .then(() =>
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </HelmetProvider>
        </Provider>
      </React.StrictMode>
    )
  )
  .catch(console.error);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
