import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IFacebookConfigFormSchema } from '@app-interfaces';
import { IFacebookConfigModel } from '@app-models';

type EditType = {
  id: number;
  data: IFacebookConfigFormSchema;
};

const CREATE_FACEBOOK_CONFIG = 'facebook:settings:CREATE_FACEBOOK_CONFIG';
const EDIT_FACEBOOK_CONFIG = 'facebook:settings:EDIT_FACEBOOK_CONFIG';
const DELETE_FACEBOOK_CONFIG = 'facebook:settings:DELETE_FACEBOOK_CONFIG';
const GET_FACEBOOK_CONFIGS = 'facebook:settings:GET_FACEBOOK_CONFIGS';
const GENERATE_WEBHOOK_TOKEN = 'facebook:settings:GENERATE_WEBHOOK_TOKEN';

const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createFacebookConfigAction = asyncThunkWrapper<
  ApiResponseSuccess<IFacebookConfigModel>,
  IFacebookConfigFormSchema
>(CREATE_FACEBOOK_CONFIG, async args => {
  const response = await client.post(`${API_ROOT}/facebook-settings`, args);
  return response.data;
});

export const editFacebookConfigAction = asyncThunkWrapper<ApiResponseSuccess<IFacebookConfigModel>, EditType>(
  EDIT_FACEBOOK_CONFIG,
  async args => {
    const response = await client.patch(`${API_ROOT}/facebook-settings/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteFacebookConfigAction = asyncThunkWrapper<ApiResponseSuccess<IFacebookConfigModel>, number>(
  DELETE_FACEBOOK_CONFIG,
  async args => {
    const response = await client.delete(`${API_ROOT}/facebook-settings/${args}`);
    return response.data;
  },
);

export const getFacebookConfigsAction = asyncThunkWrapper<ApiResponseSuccess<IFacebookConfigModel>, void>(
  GET_FACEBOOK_CONFIGS,
  async () => {
    const response = await client.get(`${API_ROOT}/facebook-settings`);
    return response.data;
  },
);

export const generateWebhookTokenAction = asyncThunkWrapper<ApiResponseSuccess<IFacebookConfigModel>, void>(
  GENERATE_WEBHOOK_TOKEN,
  async () => {
    const response = await client.get(`${API_ROOT}/facebook-webhook-token`);
    return response.data;
  },
);
