import { IThunkStatus } from '@app-types';
import { ICaseModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import { filterCasesByDateAction } from '../actions/caseReportsActions';

interface ICaseReportsState {
  filterCasesByDateStatus: IThunkStatus;
  filterCasesByDateSuccess: string;
  filterCasesByDateError: string;

  cases: ICaseModel[];
}

const initialState: ICaseReportsState = {
  filterCasesByDateStatus: 'idle',
  filterCasesByDateSuccess: '',
  filterCasesByDateError: '',
  cases: [],
};

const caseReportsSlice = createSlice({
  name: 'reports:cases',
  initialState,
  reducers: {
    resetFilterCasesByDateStatus(state: ICaseReportsState) {
      state.filterCasesByDateStatus = 'idle';
      state.filterCasesByDateSuccess = '';
      state.filterCasesByDateError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(filterCasesByDateAction.pending, state => {
        state.filterCasesByDateStatus = 'loading';
      })
      .addCase(filterCasesByDateAction.fulfilled, (state, action) => {
        state.filterCasesByDateStatus = 'completed';
        state.filterCasesByDateSuccess = action.payload.message;
        state.cases = action.payload.results as ICaseModel[];
      })
      .addCase(filterCasesByDateAction.rejected, (state, action) => {
        state.filterCasesByDateStatus = 'failed';

        if (action.payload) state.filterCasesByDateError = action.payload.message;
        else state.filterCasesByDateError = action.error.message as string;
      });
  },
});

export const { resetFilterCasesByDateStatus } = caseReportsSlice.actions;
export default caseReportsSlice.reducer;
