import store from '../store';
import { IPermissionModel } from '@app-models';
import { settings } from '../config';
import { Ability, AbilityBuilder } from '@casl/ability';

const ability = new Ability();
store.subscribe(() => {
  const { authenticationReducer } = store.getState();

  let permissions: IPermissionModel[];

  const localPermissions = JSON.parse(localStorage.getItem(settings.auth.permissions) as string);

  if (null !== localPermissions) {
    permissions = localPermissions as IPermissionModel[];

    ability.update(defineRulesFor(permissions));
  } else {
    permissions = authenticationReducer.permissions;

    if (permissions.length) {
      ability.update(defineRulesFor(permissions));
    }
  }
});

const defineRulesFor = (permissions: IPermissionModel[]) => {
  const { can, rules } = new AbilityBuilder(Ability);

  permissions.forEach((permission: IPermissionModel) => {
    const { action, subject } = permission;

    can(action, subject);
  });

  return rules;
};

export default ability;
