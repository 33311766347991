import { IThunkStatus } from '@app-types';
import { createSlice } from '@reduxjs/toolkit';
import { facebookAccessTokenAction, facebookLoginAction } from '../actions/facebookActions';

interface IFacebookState {
  facebookLoginStatus: IThunkStatus;
  facebookLoginSuccess: string;
  facebookLoginError: string;

  facebookAccessTokenStatus: IThunkStatus;
  facebookAccessTokenSuccess: string;
  facebookAccessTokenError: string;

  code: string;
  accessToken: string;
  webhookToken: string;
}

const initialState: IFacebookState = {
  facebookLoginStatus: 'idle',
  facebookLoginSuccess: '',
  facebookLoginError: '',

  facebookAccessTokenStatus: 'idle',
  facebookAccessTokenSuccess: '',
  facebookAccessTokenError: '',

  code: '',
  accessToken: '',
  webhookToken: '',
};

const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {
    resetFacebookLoginStatus(state: IFacebookState) {
      state.facebookLoginStatus = 'idle';
      state.facebookLoginSuccess = '';
      state.facebookLoginError = '';
    },
    resetFacebookAccessTokenStatus(state: IFacebookState) {
      state.facebookAccessTokenStatus = 'idle';
      state.facebookAccessTokenSuccess = '';
      state.facebookAccessTokenError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(facebookLoginAction.pending, state => {
        state.facebookLoginStatus = 'loading';
      })
      .addCase(facebookLoginAction.fulfilled, (state, action) => {
        state.facebookLoginStatus = 'completed';
        state.facebookLoginSuccess = action.payload.message;
        state.code = action.payload.result;
      })
      .addCase(facebookLoginAction.rejected, (state, action) => {
        state.facebookLoginStatus = 'failed';

        if (action.payload) state.facebookLoginError = action.payload.message;
        else state.facebookLoginError = action.error.message as string;
      });

    builder
      .addCase(facebookAccessTokenAction.pending, state => {
        state.facebookAccessTokenStatus = 'loading';
      })
      .addCase(facebookAccessTokenAction.fulfilled, (state, action) => {
        state.facebookAccessTokenStatus = 'completed';
        state.facebookAccessTokenSuccess = action.payload.message;
        state.webhookToken = action.payload.result as string;
      })
      .addCase(facebookAccessTokenAction.rejected, (state, action) => {
        state.facebookAccessTokenStatus = 'failed';

        if (action.payload) state.facebookAccessTokenError = action.payload.message;
        else state.facebookAccessTokenError = action.error.message as string;
      });
  },
});

export const { resetFacebookLoginStatus, resetFacebookAccessTokenStatus } = facebookSlice.actions;
export default facebookSlice.reducer;
