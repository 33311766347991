import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';

import socketAction from '../actions/socketAction';
import { IThunkStatus } from '@app-types';

interface ISocketState {
  initSocketStatus: IThunkStatus;
  initSocketSuccess: string;
  initSocketError?: string;
  notifyMail: boolean;
  socket: Socket | unknown;
}

const initialState: ISocketState = {
  initSocketStatus: 'idle',
  initSocketSuccess: '',
  initSocketError: '',
  notifyMail: false,
  socket: null,
};
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    resetInitSocketStatus(state: ISocketState) {
      state.initSocketStatus = 'idle';
      state.initSocketError = '';
      state.initSocketSuccess = '';
    },

    setMailNotify(state: ISocketState, action: PayloadAction<boolean>) {
      state.notifyMail = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(socketAction.pending, state => {
        state.initSocketStatus = 'loading';
      })
      .addCase(socketAction.fulfilled, (state, action) => {
        state.initSocketStatus = 'completed';
        state.socket = action.payload;
      })
      .addCase(socketAction.rejected, (state, action) => {
        state.initSocketError = action.error.message;
      });
  },
});

export const { resetInitSocketStatus, setMailNotify } = socketSlice.actions;

export default socketSlice.reducer;
