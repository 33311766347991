import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IDailySummary, IMonthlySummary } from '@app-interfaces';

const GET_DAILY_SUMMARY = 'dashboard:GET_DAILY_SUMMARY';
const GET_MONTHLY_SUMMARY = 'dashboard:GET_MONTHLY_SUMMARY';
const GET_CASE_PROPS_SUMMARY = 'dashboard:GET_CASE_PROPS_SUMMARY';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const getDailySummaryAction = asyncThunkWrapper<ApiResponseSuccess<IDailySummary>, void>(
  GET_DAILY_SUMMARY,
  async () => {
    const response = await client.get(`${API_ROOT}/dashboard/daily-summary`);

    return response.data;
  },
);

type MonthlySummaryType = Record<string, IMonthlySummary[]>;
export const getMonthlySummaryAction = asyncThunkWrapper<ApiResponseSuccess<MonthlySummaryType>, void>(
  GET_MONTHLY_SUMMARY,
  async () => {
    const response = await client.get(`${API_ROOT}/dashboard/monthly-summary`);

    return response.data;
  },
);

type CasePropsSummaryType = Record<string, { labels: string[]; data: number[] }>;
export const getCasePropsSummaryAction = asyncThunkWrapper<ApiResponseSuccess<CasePropsSummaryType>, void>(
  GET_CASE_PROPS_SUMMARY,
  async () => {
    const response = await client.get(`${API_ROOT}/dashboard/case-props-summary`);

    return response.data;
  },
);
