import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IEvaluationSchema } from '@app-interfaces';
import { IEvaluationModel } from '@app-models';

const CREATE_EVALUATION = 'evaluation:CREATE_EVALUATION';
const EDIT_EVALUATION = 'evaluation:EDIT_EVALUATION';
const DELETE_EVALUATION = 'evaluation:DELETE_EVALUATION';
const GET_EVALUATION = 'evaluation:GET_EVALUATION';
const GET_EVALUATIONS = 'evaluation:GET_EVALUATIONS';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createEvaluationAction = asyncThunkWrapper<ApiResponseSuccess<IEvaluationModel>, IEvaluationSchema>(
  CREATE_EVALUATION,
  async args => {
    const response = await client.post(`${API_ROOT}/evaluations`, args);

    return response.data;
  },
);

interface EditArgs {
  id: number;
  data: IEvaluationSchema;
}

export const editEvaluationAction = asyncThunkWrapper<ApiResponseSuccess<IEvaluationModel>, EditArgs>(
  EDIT_EVALUATION,
  async args => {
    const response = await client.patch(`${API_ROOT}/evaluations/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteEvaluationAction = asyncThunkWrapper<ApiResponseSuccess<IEvaluationModel>, number>(
  DELETE_EVALUATION,
  async args => {
    const response = await client.delete(`${API_ROOT}/evaluations/${args}`);

    return response.data;
  },
);

export const getEvaluationAction = asyncThunkWrapper<ApiResponseSuccess<IEvaluationModel>, number>(
  GET_EVALUATION,
  async args => {
    const response = await client.get(`${API_ROOT}/evaluations/${args}`);

    return response.data;
  },
);

export const getEvaluationsAction = asyncThunkWrapper<ApiResponseSuccess<IEvaluationModel>, void>(
  GET_EVALUATIONS,
  async () => {
    const response = await client.get(`${API_ROOT}/evaluations`);

    return response.data;
  },
);
