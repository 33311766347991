import { createSlice } from '@reduxjs/toolkit';
import { IThunkStatus } from '@app-types';
import { IEvaluationModel } from '@app-models';
import {
  createEvaluationAction,
  deleteEvaluationAction,
  editEvaluationAction,
  getEvaluationAction,
  getEvaluationsAction,
} from '../actions/evaluationActions';

interface IEvaluationState {
  createEvaluationStatus: IThunkStatus;
  createEvaluationSuccess: string;
  createEvaluationError: string;

  editEvaluationStatus: IThunkStatus;
  editEvaluationSuccess: string;
  editEvaluationError: string;

  deleteEvaluationStatus: IThunkStatus;
  deleteEvaluationSuccess: string;
  deleteEvaluationError: string;

  getEvaluationStatus: IThunkStatus;
  getEvaluationSuccess: string;
  getEvaluationError: string;

  getEvaluationsStatus: IThunkStatus;
  getEvaluationsSuccess: string;
  getEvaluationsError: string;

  evaluation: IEvaluationModel | null;
  evaluations: IEvaluationModel[];
}

const initialState: IEvaluationState = {
  createEvaluationStatus: 'idle',
  createEvaluationSuccess: '',
  createEvaluationError: '',

  editEvaluationStatus: 'idle',
  editEvaluationSuccess: '',
  editEvaluationError: '',

  deleteEvaluationStatus: 'idle',
  deleteEvaluationSuccess: '',
  deleteEvaluationError: '',

  getEvaluationStatus: 'idle',
  getEvaluationSuccess: '',
  getEvaluationError: '',

  getEvaluationsStatus: 'idle',
  getEvaluationsSuccess: '',
  getEvaluationsError: '',

  evaluation: null,
  evaluations: [],
};

const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    resetCreateEvaluationStatus(state: IEvaluationState) {
      state.createEvaluationStatus = 'idle';
      state.createEvaluationSuccess = '';
      state.createEvaluationError = '';
    },
    resetEditEvaluationStatus(state: IEvaluationState) {
      state.editEvaluationStatus = 'idle';
      state.editEvaluationSuccess = '';
      state.editEvaluationError = '';
    },
    resetDeleteEvaluationStatus(state: IEvaluationState) {
      state.deleteEvaluationStatus = 'idle';
      state.deleteEvaluationSuccess = '';
      state.deleteEvaluationError = '';
    },
    resetGetEvaluationStatus(state: IEvaluationState) {
      state.getEvaluationStatus = 'idle';
      state.getEvaluationSuccess = '';
      state.getEvaluationError = '';
    },
    resetGetEvaluationsStatus(state: IEvaluationState) {
      state.getEvaluationsStatus = 'idle';
      state.getEvaluationsSuccess = '';
      state.getEvaluationsError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createEvaluationAction.pending, state => {
        state.createEvaluationStatus = 'loading';
      })
      .addCase(createEvaluationAction.fulfilled, (state, action) => {
        state.createEvaluationStatus = 'completed';
        state.createEvaluationSuccess = action.payload.message;
        state.evaluations.push(action.payload.result as IEvaluationModel);
      })
      .addCase(createEvaluationAction.rejected, (state, action) => {
        state.createEvaluationStatus = 'completed';
        if (action.payload) state.createEvaluationError = action.payload.message;
        else state.createEvaluationError = action.error.message as string;
      });

    builder
      .addCase(editEvaluationAction.pending, state => {
        state.editEvaluationStatus = 'loading';
      })
      .addCase(editEvaluationAction.fulfilled, (state, action) => {
        state.editEvaluationStatus = 'completed';
        state.editEvaluationSuccess = action.payload.message;
        const currentEval = action.payload.result as IEvaluationModel;
        const prevEvalIndex = state.evaluations.findIndex(item => item.id === currentEval.id);

        if (prevEvalIndex) state.evaluations.splice(prevEvalIndex, 1, currentEval);
      })
      .addCase(editEvaluationAction.rejected, (state, action) => {
        state.editEvaluationStatus = 'completed';
        if (action.payload) state.editEvaluationError = action.payload.message;
        else state.editEvaluationError = action.error.message as string;
      });

    builder
      .addCase(deleteEvaluationAction.pending, state => {
        state.deleteEvaluationStatus = 'loading';
      })
      .addCase(deleteEvaluationAction.fulfilled, (state, action) => {
        state.deleteEvaluationStatus = 'completed';
        state.deleteEvaluationSuccess = action.payload.message;

        const currentEval = action.payload.result as IEvaluationModel;
        const prevEvalIndex = state.evaluations.findIndex(item => item.id === currentEval.id);

        if (prevEvalIndex) state.evaluations.splice(prevEvalIndex, 1);
      })
      .addCase(deleteEvaluationAction.rejected, (state, action) => {
        state.deleteEvaluationStatus = 'completed';
        if (action.payload) state.deleteEvaluationError = action.payload.message;
        else state.deleteEvaluationError = action.error.message as string;
      });

    builder
      .addCase(getEvaluationAction.pending, state => {
        state.getEvaluationStatus = 'loading';
      })
      .addCase(getEvaluationAction.fulfilled, (state, action) => {
        state.getEvaluationStatus = 'completed';
        state.getEvaluationSuccess = action.payload.message;
        state.evaluation = action.payload.result as IEvaluationModel;
      })
      .addCase(getEvaluationAction.rejected, (state, action) => {
        state.getEvaluationStatus = 'completed';
        if (action.payload) state.getEvaluationError = action.payload.message;
        else state.getEvaluationError = action.error.message as string;
      });

    builder
      .addCase(getEvaluationsAction.pending, state => {
        state.getEvaluationsStatus = 'loading';
      })
      .addCase(getEvaluationsAction.fulfilled, (state, action) => {
        state.getEvaluationsStatus = 'completed';
        state.getEvaluationsSuccess = action.payload.message;
        state.evaluations = action.payload.results as IEvaluationModel[];
      })
      .addCase(getEvaluationsAction.rejected, (state, action) => {
        state.getEvaluationsStatus = 'completed';
        if (action.payload) state.getEvaluationsError = action.payload.message;
        else state.getEvaluationsError = action.error.message as string;
      });
  },
});

export const {
  resetCreateEvaluationStatus,
  resetEditEvaluationStatus,
  resetGetEvaluationsStatus,
  resetDeleteEvaluationStatus,
  resetGetEvaluationStatus,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;
