import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { settings } from '../../config';
import { ApiResponseSuccess } from '@app-interfaces';
import { IAgentGroupModel, IAgentModel } from '@app-models';

const CREATE_AGENT = 'agent-mgt:CREATE_AGENT';
const EDIT_AGENT = 'agent-mgt:EDIT_AGENT';
const DELETE_AGENT = 'agent-mgt:DELETE_AGENT';
const GET_AGENTS = 'agent-mgt:GET_AGENTS';
const GET_AGENT = 'agent-mgt:GET_AGENT';

const CREATE_AGENT_GROUP = 'agent-mgt:CREATE_AGENT_GROUP';
const EDIT_AGENT_GROUP = 'agent-mgt:EDIT_AGENT_GROUP';
const DELETE_AGENT_GROUP = 'agent-mgt:DELETE_AGENT_GROUP';
const GET_AGENT_GROUPS = 'agent-mgt:GET_AGENT_GROUPS';
const GET_AGENT_GROUP = 'agent-mgt:GET_AGENT_GROUP';

const API_ROOT = settings.api.root;

export const createAgentAction = asyncThunkWrapper<ApiResponseSuccess<IAgentModel>, unknown>(
  CREATE_AGENT,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.post(`${API_ROOT}/agents`, args);
    return response.data;
  },
);

type EditAgent = { id: number; data: unknown };
export const editAgentAction = asyncThunkWrapper<ApiResponseSuccess<IAgentModel>, EditAgent>(
  EDIT_AGENT,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.patch(`${API_ROOT}/agents/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteAgentAction = asyncThunkWrapper<ApiResponseSuccess<IAgentModel>, number>(
  DELETE_AGENT,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.delete(`${API_ROOT}/agents/${args}`);
    return response.data;
  },
);

export const getAgentAction = asyncThunkWrapper<ApiResponseSuccess<IAgentModel>, number>(
  GET_AGENT,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/agents/${args}`);
    return response.data;
  },
);

export const getAgentsAction = asyncThunkWrapper<ApiResponseSuccess<IAgentModel>, void>(
  GET_AGENTS,
  async (_, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/agents`);
    return response.data;
  },
);

export const createAgentGroupAction = asyncThunkWrapper<ApiResponseSuccess<IAgentGroupModel>, unknown>(
  CREATE_AGENT_GROUP,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.post(`${API_ROOT}/agent-groups`, args);
    return response.data;
  },
);

type EditAgentGroup = { id: number; data: unknown };
export const editAgentGroupAction = asyncThunkWrapper<ApiResponseSuccess<IAgentGroupModel>, EditAgentGroup>(
  EDIT_AGENT_GROUP,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.patch(`${API_ROOT}/agent-groups/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteAgentGroupAction = asyncThunkWrapper<ApiResponseSuccess<IAgentGroupModel>, number>(
  DELETE_AGENT_GROUP,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.delete(`${API_ROOT}/agent-groups/${args}`);
    return response.data;
  },
);

export const getAgentGroupAction = asyncThunkWrapper<ApiResponseSuccess<IAgentGroupModel>, number>(
  GET_AGENT_GROUP,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/agent-groups/${args}`);
    return response.data;
  },
);

export const getAgentGroupsAction = asyncThunkWrapper<ApiResponseSuccess<IAgentGroupModel>, void>(
  GET_AGENT_GROUPS,
  async (_, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/agent-groups`);
    return response.data;
  },
);
