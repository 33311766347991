import { IThunkStatus } from '@app-types';
import { ICaseEmailModel } from '@app-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filterEmailsByDateAction, getAllEmailsAction } from '../actions/emailActions';

interface IEmailState {
  getEmailsStatus: IThunkStatus;
  getEmailsSuccess: string;
  getEmailsError: string;
  filterEmailsByDateStatus: IThunkStatus;
  filterEmailsByDateSuccess: string;
  filterEmailsByDateError: string;
  emails: ICaseEmailModel[];
  email: ICaseEmailModel | null;
}

const initialState: IEmailState = {
  getEmailsStatus: 'idle',
  getEmailsSuccess: '',
  getEmailsError: '',

  filterEmailsByDateStatus: 'idle',
  filterEmailsByDateSuccess: '',
  filterEmailsByDateError: '',

  emails: [],
  email: null,
};

const emailSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    resetGetEmailsStatus(state: IEmailState) {
      state.getEmailsStatus = 'idle';
      state.getEmailsSuccess = '';
      state.getEmailsError = '';
    },
    resetFilterEmailsByDateStatus(state: IEmailState) {
      state.filterEmailsByDateStatus = 'idle';
      state.filterEmailsByDateSuccess = '';
      state.filterEmailsByDateError = '';
    },
    setMailsAction(state: IEmailState, action: PayloadAction<ICaseEmailModel[]>) {
      state.emails = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllEmailsAction.pending, state => {
        state.getEmailsStatus = 'loading';
      })
      .addCase(getAllEmailsAction.fulfilled, (state, action) => {
        state.getEmailsStatus = 'completed';
        state.getEmailsSuccess = action.payload.message;
        state.emails = action.payload.results as ICaseEmailModel[];
      })
      .addCase(getAllEmailsAction.rejected, (state, action) => {
        state.getEmailsStatus = 'failed';

        if (action.payload) state.getEmailsError = action.payload.message;
        else state.getEmailsError = action.error.message as string;
      });

    builder
      .addCase(filterEmailsByDateAction.pending, state => {
        state.filterEmailsByDateStatus = 'loading';
      })
      .addCase(filterEmailsByDateAction.fulfilled, (state, action) => {
        state.filterEmailsByDateStatus = 'completed';
        state.filterEmailsByDateSuccess = action.payload.message;
        state.emails = action.payload.results as ICaseEmailModel[];
      })
      .addCase(filterEmailsByDateAction.rejected, (state, action) => {
        state.filterEmailsByDateStatus = 'failed';

        if (action.payload) state.filterEmailsByDateError = action.payload.message;
        else state.filterEmailsByDateError = action.error.message as string;
      });
  },
});

export const { resetGetEmailsStatus, setMailsAction, resetFilterEmailsByDateStatus } = emailSlice.actions;
export default emailSlice.reducer;
