import { IThunkStatus } from '@app-types';
import { ICaseModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createCaseAction,
  deleteCaseAction,
  editCaseAction,
  getCasesAction,
  getCustomerCasesAction,
  importCasesOnlyAction,
  importNewAndExistingCasesAction,
  sendCaseEmailAction,
} from '../actions/caseActions';

interface ICaseState {
  createCaseStatus: IThunkStatus;
  createCaseSuccess: string;
  createCaseError: string;

  editCaseStatus: IThunkStatus;
  editCaseSuccess: string;
  editCaseError: string;

  deleteCaseStatus: IThunkStatus;
  deleteCaseSuccess: string;
  deleteCaseError: string;

  getCasesStatus: IThunkStatus;
  getCasesSuccess: string;
  getCasesError: string;

  importCasesOnlyStatus: IThunkStatus;
  importCasesOnlySuccess: string;
  importCasesOnlyError: string;

  importNewAndExistingCasesStatus: IThunkStatus;
  importNewAndExistingCasesSuccess: string;
  importNewAndExistingCasesError: string;

  sendCaseEmailStatus: IThunkStatus;
  sendCaseEmailSuccess: string;
  sendCaseEmailError: string;

  getCustomerCasesStatus: IThunkStatus;
  getCustomerCasesSuccess: string;
  getCustomerCasesError: string;

  case: ICaseModel | null;
  cases: ICaseModel[];
  customerCases: ICaseModel[];
}

const initialState: ICaseState = {
  createCaseStatus: 'idle',
  createCaseSuccess: '',
  createCaseError: '',

  editCaseStatus: 'idle',
  editCaseSuccess: '',
  editCaseError: '',

  deleteCaseStatus: 'idle',
  deleteCaseSuccess: '',
  deleteCaseError: '',

  getCasesStatus: 'idle',
  getCasesSuccess: '',
  getCasesError: '',

  importCasesOnlyError: '',
  importCasesOnlyStatus: 'idle',
  importCasesOnlySuccess: '',
  importNewAndExistingCasesError: '',
  importNewAndExistingCasesStatus: 'idle',
  importNewAndExistingCasesSuccess: '',
  sendCaseEmailError: '',
  sendCaseEmailStatus: 'idle',
  sendCaseEmailSuccess: '',
  getCustomerCasesError: '',
  getCustomerCasesStatus: 'idle',
  getCustomerCasesSuccess: '',

  case: null,
  cases: [],
  customerCases: [],
};

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    resetCreateCaseStatus(state: ICaseState) {
      state.createCaseStatus = 'idle';
      state.createCaseSuccess = '';
      state.createCaseError = '';
    },
    resetEditCaseStatus(state: ICaseState) {
      state.editCaseStatus = 'idle';
      state.editCaseSuccess = '';
      state.editCaseError = '';
    },
    resetDeleteCaseStatus(state: ICaseState) {
      state.deleteCaseStatus = 'idle';
      state.deleteCaseSuccess = '';
      state.deleteCaseError = '';
    },
    resetGetCasesStatus(state: ICaseState) {
      state.getCasesStatus = 'idle';
      state.getCasesSuccess = '';
      state.getCasesError = '';
    },
    resetImportCasesOnly(state: ICaseState) {
      state.importCasesOnlyStatus = 'idle';
      state.importCasesOnlySuccess = '';
      state.importCasesOnlyError = '';
    },
    resetImportNewAndExistingCases(state: ICaseState) {
      state.importNewAndExistingCasesStatus = 'idle';
      state.importNewAndExistingCasesSuccess = '';
      state.importNewAndExistingCasesError = '';
    },
    resetSendCaseEmail(state: ICaseState) {
      state.sendCaseEmailStatus = 'idle';
      state.sendCaseEmailSuccess = '';
      state.sendCaseEmailError = '';
    },
    resetGetCustomerCasesStatus(state: ICaseState) {
      state.getCustomerCasesStatus = 'idle';
      state.getCustomerCasesSuccess = '';
      state.getCustomerCasesError = '';
      state.customerCases = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCaseAction.pending, state => {
        state.createCaseStatus = 'loading';
      })
      .addCase(createCaseAction.fulfilled, (state, action) => {
        state.createCaseStatus = 'completed';
        state.createCaseSuccess = action.payload.message;

        if (action.payload.result) {
          const $case = action.payload.result;
          state.case = $case;
          state.cases.push($case);
        }
      })
      .addCase(createCaseAction.rejected, (state, action) => {
        state.createCaseStatus = 'failed';

        if (action.payload) state.createCaseError = action.payload.message;
        else state.createCaseError = action.error.message as string;
      });

    builder
      .addCase(editCaseAction.pending, state => {
        state.editCaseStatus = 'loading';
      })
      .addCase(editCaseAction.fulfilled, (state, action) => {
        state.editCaseStatus = 'completed';
        state.editCaseSuccess = action.payload.message;

        if (action.payload.result) {
          const $case = action.payload.result;

          const cases = [...state.cases];

          const prevCase = cases.find(item => item.id === $case.id);

          if (prevCase) {
            const index = cases.indexOf(prevCase);

            state.case = $case;
            state.cases.splice(index, 1, $case);
          }
        }
      })
      .addCase(editCaseAction.rejected, (state, action) => {
        state.editCaseStatus = 'failed';

        if (action.payload) state.editCaseError = action.payload.message;
        else state.editCaseError = action.error.message as string;
      });

    builder
      .addCase(deleteCaseAction.pending, state => {
        state.deleteCaseStatus = 'loading';
      })
      .addCase(deleteCaseAction.fulfilled, (state, action) => {
        state.deleteCaseStatus = 'completed';
        state.deleteCaseSuccess = action.payload.message;

        if (action.payload.result) {
          const $case = action.payload.result;

          const cases = [...state.cases];

          const prevCase = cases.find(item => item.id === $case.id);

          if (prevCase) {
            const index = cases.indexOf(prevCase);

            state.case = $case;
            state.cases.splice(index, 1);
          }
        }
      })
      .addCase(deleteCaseAction.rejected, (state, action) => {
        state.deleteCaseStatus = 'failed';

        if (action.payload) state.deleteCaseError = action.payload.message;
        else state.deleteCaseError = action.error.message as string;
      });

    builder
      .addCase(getCasesAction.pending, state => {
        state.getCasesStatus = 'loading';
      })
      .addCase(getCasesAction.fulfilled, (state, action) => {
        state.getCasesStatus = 'completed';
        state.getCasesSuccess = action.payload.message;

        if (action.payload.results) state.cases = action.payload.results;
      })
      .addCase(getCasesAction.rejected, (state, action) => {
        state.getCasesStatus = 'failed';

        if (action.payload) state.getCasesError = action.payload.message;
        else state.getCasesError = action.error.message as string;
      });

    builder
      .addCase(importCasesOnlyAction.pending, state => {
        state.importCasesOnlyStatus = 'loading';
      })
      .addCase(importCasesOnlyAction.fulfilled, (state, action) => {
        state.importCasesOnlyStatus = 'completed';
        state.importCasesOnlySuccess = action.payload.message;
      })
      .addCase(importCasesOnlyAction.rejected, (state, action) => {
        state.importCasesOnlyStatus = 'failed';

        if (action.payload) state.importCasesOnlyError = action.payload.message;
        else state.importCasesOnlyError = action.error.message as string;
      });

    builder
      .addCase(importNewAndExistingCasesAction.pending, state => {
        state.importNewAndExistingCasesStatus = 'loading';
      })
      .addCase(importNewAndExistingCasesAction.fulfilled, (state, action) => {
        state.importNewAndExistingCasesStatus = 'completed';
        state.importNewAndExistingCasesSuccess = action.payload.message;
      })
      .addCase(importNewAndExistingCasesAction.rejected, (state, action) => {
        state.importNewAndExistingCasesStatus = 'failed';

        if (action.payload) state.importNewAndExistingCasesError = action.payload.message;
        else state.importNewAndExistingCasesError = action.error.message as string;
      });

    builder
      .addCase(sendCaseEmailAction.pending, state => {
        state.sendCaseEmailStatus = 'loading';
      })
      .addCase(sendCaseEmailAction.fulfilled, (state, action) => {
        state.sendCaseEmailStatus = 'completed';
        state.sendCaseEmailSuccess = action.payload.message;
      })
      .addCase(sendCaseEmailAction.rejected, (state, action) => {
        state.sendCaseEmailStatus = 'failed';

        if (action.payload) state.sendCaseEmailError = action.payload.message;
        else state.sendCaseEmailError = action.error.message as string;
      });

    builder
      .addCase(getCustomerCasesAction.pending, state => {
        state.getCustomerCasesStatus = 'loading';
      })
      .addCase(getCustomerCasesAction.fulfilled, (state, action) => {
        state.getCustomerCasesStatus = 'completed';
        state.getCustomerCasesSuccess = action.payload.message;
        state.customerCases = action.payload.results as ICaseModel[];
      })
      .addCase(getCustomerCasesAction.rejected, (state, action) => {
        state.getCustomerCasesStatus = 'failed';

        if (action.payload) state.getCustomerCasesError = action.payload.message;
        else state.getCustomerCasesError = action.error.message as string;
      });
  },
});

export const {
  resetCreateCaseStatus,
  resetGetCasesStatus,
  resetEditCaseStatus,
  resetDeleteCaseStatus,
  resetImportNewAndExistingCases,
  resetImportCasesOnly,
  resetSendCaseEmail,
  resetGetCustomerCasesStatus,
} = caseSlice.actions;
export default caseSlice.reducer;
