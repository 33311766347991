import { createAsyncThunk } from '@reduxjs/toolkit';
import JsSip from 'jssip';

const INIT_SIP_CLIENT = 'webrtc:INIT_SIP_CLIENT';

export const initSipClientAction = createAsyncThunk<JsSip.WebSocketInterface, { host: string; port: number }>(
  INIT_SIP_CLIENT,
  arg => {
    const WEBRTC_URL = `wss://${arg.host}:${arg.port}/ws`;

    return new JsSip.WebSocketInterface(WEBRTC_URL);
  }
);
