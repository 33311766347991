export enum LayoutDimension {
  DRAWER_WIDTH = 280,
}

export enum NavBar {
  BASE_WIDTH = 260,
  DASHBOARD_WIDTH = 280,
  DASHBOARD_COLLAPSE_WIDTH = 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT = 48,
  DASHBOARD_ITEM_SUB_HEIGHT = 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT = 32,
}

export enum Header {
  MOBILE_HEIGHT = 64,
  MAIN_DESKTOP_HEIGHT = 88,
  DASHBOARD_DESKTOP_HEIGHT = 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT = 92 - 32,
}

export enum ErrorMessage {
  NOT_FOUND = 'The Page you are looking for does not exist.',
  BAD_REQUEST = 'Bad request. Please contact support.',
  INTERNAL_SERVER_ERROR = 'An error occurred, please contact support.',
  PASSWORD_INVALID = `Password must contain at least: 
      1 uppercase letter
      1 lowercase letter
      special characters e.g @!%&
      and between 8 to 20 characters long`,
  PHONE_INVALID = 'Phone number must be 11 digits long. e.g 080-XX-XX-XX-XX',
}

export enum Messages {
  DELETE = `Are you sure you want to carry out this action? 
  If you agree to do this, you can not undo this action. Do you still want to proceed?`,
}

export enum Authentication {
  PASSWORD_PATTERN = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%^&+=])(?=\\S+$).{8,20}$',
}

export enum Http {
  APPLICATION_JSON = 'application/json',
  MULTI_PART_FORM_DATA = 'multipart/form-data',
}

export enum CaseState {
  Open = 'Open',
  Closed = 'Closed',
}

export enum CaseStatus {
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  Rejected = 'Rejected',
}

export enum CasePriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum CaseDisposition {
  Irate = 'Irate',
  Calm = 'Calm',
}

export enum SocketEvents {
  MAIL_RECEIVED = 'MAIL_RECEIVED',
  MAIL_READ = 'MAIL_READ',
}

export enum NotificationTypes {
  Email = 'Email',
  Case = 'Case',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Undisclosed = 'Undisclosed',
}

export enum MailLabels {
  sent = 'SENT',
  inbox = 'INBOX',
  drafts = 'DRAFTS',
  trash = 'TRASH',
}

export enum MailState {
  delivered = 'DELIVERED',
  received = 'RECEIVED',
}

export enum FacebookStatus {
  active = 'Active',
  inactive = 'Inactive',
}
