import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ICaseReportFilterSchema } from '@app-interfaces';
import { ICaseEmailModel } from '@app-models';

const GET_EMAILS = 'emails:GET_EMAILS';
const FILTER_EMAILS_BY_DATE = 'emails:FILTER_EMAILS_BY_DATE';
const API_ROOT = settings.api.root;

const client = apiClient({ token: true });

export const getAllEmailsAction = asyncThunkWrapper<ApiResponseSuccess<ICaseEmailModel>, void>(GET_EMAILS, async () => {
  const response = await client.get(`${API_ROOT}/emails`);

  return response.data;
});

type FilterType = Pick<ICaseReportFilterSchema, 'startDate' | 'endDate'>;
export const filterEmailsByDateAction = asyncThunkWrapper<ApiResponseSuccess<ICaseEmailModel>, FilterType>(
  FILTER_EMAILS_BY_DATE,
  async args => {
    const response = await client.post(`${API_ROOT}/reports/emails`, args);

    return response.data;
  },
);
