import React, { useMemo } from 'react';
import { LottieOptions, useLottie } from 'lottie-react';

import Loader from '../assets/plugins/lottie/loader.json';
import { Backdrop } from '@mui/material';

type IAppProps = {
  height?: number;
  width?: number;
  show: boolean;
};

function AppLoader({ height = 200, width = 200, show = false }: IAppProps) {
  const defaultOptions: LottieOptions = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: Loader,
      width,
      height,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [height, width]);

  const { View } = useLottie(defaultOptions);

  return (
    <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={show}>
      {View}
    </Backdrop>
  );
}

export default AppLoader;
