import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess } from '@app-interfaces';
import { apiClient, settings } from '../../config';

const FACEBOOK_LOGIN = 'facebook:auth:FACEBOOK_LOGIN';
const GET_ACCESS_TOKEN = 'facebook:auth:GET_ACCESS_TOKEN';
const API_ROOT = settings.api.root;

const client = apiClient({ token: true });

export const facebookLoginAction = asyncThunkWrapper<ApiResponseSuccess<any>, { appId: string; redirectUri: string }>(
  FACEBOOK_LOGIN,
  async args => {
    const response = await client.post(`${API_ROOT}/facebook/login`, args);

    return response.data;
  },
);

export const facebookAccessTokenAction = asyncThunkWrapper<
  ApiResponseSuccess<string>,
  { code: string; redirectUri: string }
>(GET_ACCESS_TOKEN, async args => {
  const response = await client.post(`${API_ROOT}/facebook/access_token`, args);

  return response.data;
});
