import { CustomJWTPayload, IThunkStatus } from '@app-types';
import { IPermissionModel, IRoleModel, IUserModel } from '@app-models';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  createRoleAction,
  createUserAction,
  deleteRoleAction,
  deleteUserAction,
  editRoleAction,
  editUserAction,
  getPermissionsAction,
  getRolesAction,
  getUsersAction,
  loginAction,
  logoutAction,
  updateSocketIdAction,
} from '../actions/authenticationActions';
import { settings } from '../../config';
import jwtDecode from 'jwt-decode';

interface IAuthenticationState {
  getPermissionsStatus: IThunkStatus;
  getPermissionsSuccess: string;
  getPermissionsError: string;

  loginStatus: IThunkStatus;
  loginSuccess: string;
  loginError: string;

  logoutStatus: IThunkStatus;
  logoutSuccess: string;
  logoutError: string;

  createRoleStatus: IThunkStatus;
  createRoleSuccess: string;
  createRoleError: string;

  getRolesStatus: IThunkStatus;
  getRolesSuccess: string;
  getRolesError: string;

  deleteRoleStatus: IThunkStatus;
  deleteRoleSuccess: string;
  deleteRoleError: string;

  editRoleStatus: IThunkStatus;
  editRoleSuccess: string;
  editRoleError: string;

  createUserStatus: IThunkStatus;
  createUserSuccess: string;
  createUserError: string;

  getUsersStatus: IThunkStatus;
  getUsersSuccess: string;
  getUsersError: string;

  deleteUserStatus: IThunkStatus;
  deleteUserSuccess: string;
  deleteUserError: string;

  editUserStatus: IThunkStatus;
  editUserSuccess: string;
  editUserError: string;

  updateSocketIdStatus: IThunkStatus;
  updateSocketIdSuccess: string;
  updateSocketIdError: string;

  permissions: IPermissionModel[];
  role: IRoleModel | null;
  user: IUserModel | null;
  roles: IRoleModel[];
  users: IUserModel[];
}

const initialState: IAuthenticationState = {
  getPermissionsStatus: 'idle',
  getPermissionsSuccess: '',
  getPermissionsError: '',

  loginStatus: 'idle',
  loginSuccess: '',
  loginError: '',

  logoutStatus: 'idle',
  logoutSuccess: '',
  logoutError: '',

  createRoleStatus: 'idle',
  createRoleSuccess: '',
  createRoleError: '',

  getRolesStatus: 'idle',
  getRolesSuccess: '',
  getRolesError: '',

  deleteRoleStatus: 'idle',
  deleteRoleSuccess: '',
  deleteRoleError: '',

  editRoleStatus: 'idle',
  editRoleSuccess: '',
  editRoleError: '',

  createUserStatus: 'idle',
  createUserSuccess: '',
  createUserError: '',

  getUsersStatus: 'idle',
  getUsersSuccess: '',
  getUsersError: '',

  deleteUserStatus: 'idle',
  deleteUserSuccess: '',
  deleteUserError: '',

  editUserStatus: 'idle',
  editUserSuccess: '',
  editUserError: '',

  updateSocketIdStatus: 'idle',
  updateSocketIdSuccess: '',
  updateSocketIdError: '',

  permissions: [],
  roles: [],
  role: null,
  users: [],
  user: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    resetGetPermissionsStatus(state: IAuthenticationState) {
      state.getPermissionsStatus = 'idle';
      state.getPermissionsSuccess = '';
      state.getPermissionsError = '';
    },

    resetLoginStatus(state: IAuthenticationState) {
      state.loginStatus = 'idle';
      state.loginSuccess = '';
      state.loginError = '';
    },

    resetLogoutStatus(state: IAuthenticationState) {
      state.logoutStatus = 'idle';
      state.logoutSuccess = '';
      state.logoutError = '';
    },

    resetCreateRoleStatus(state: IAuthenticationState) {
      state.createRoleStatus = 'idle';
      state.createRoleSuccess = '';
      state.createRoleError = '';
    },
    resetGetRolesStatus(state: IAuthenticationState) {
      state.getRolesStatus = 'idle';
      state.getRolesSuccess = '';
      state.getRolesError = '';
    },
    resetDeleteRoleStatus(state: IAuthenticationState) {
      state.deleteRoleStatus = 'idle';
      state.deleteRoleSuccess = '';
      state.deleteRoleError = '';
    },
    resetEditRoleStatus(state: IAuthenticationState) {
      state.editRoleStatus = 'idle';
      state.editRoleSuccess = '';
      state.editRoleError = '';
    },

    resetCreateUserStatus(state: IAuthenticationState) {
      state.createUserStatus = 'idle';
      state.createUserSuccess = '';
      state.createUserError = '';
    },

    resetGetUsersStatus(state: IAuthenticationState) {
      state.getUsersStatus = 'idle';
      state.getUsersSuccess = '';
      state.getUsersError = '';
    },
    resetDeleteUserStatus(state: IAuthenticationState) {
      state.deleteUserStatus = 'idle';
      state.deleteUserSuccess = '';
      state.deleteUserError = '';
    },
    resetEditUserStatus(state: IAuthenticationState) {
      state.editUserStatus = 'idle';
      state.editUserSuccess = '';
      state.editUserError = '';
    },

    resetStore(state: IAuthenticationState) {
      console.log(current(state));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPermissionsAction.pending, state => {
        state.getPermissionsStatus = 'loading';
      })
      .addCase(getPermissionsAction.fulfilled, (state, action) => {
        state.getPermissionsStatus = 'completed';
        state.getPermissionsSuccess = action.payload.message;

        if (action.payload.results) state.permissions = action.payload.results;
      })
      .addCase(getPermissionsAction.rejected, (state, action) => {
        state.getPermissionsStatus = 'failed';

        if (action.payload) state.getPermissionsError = action.payload.message;
        else state.getPermissionsError = action.error.message as string;
      });

    builder
      .addCase(loginAction.pending, state => {
        state.loginStatus = 'loading';
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.loginStatus = 'completed';
        state.loginSuccess = action.payload.message;

        if (action.payload.result) {
          const jwt = action.payload.result;
          localStorage.setItem(settings.auth.loginAuth, jwt);

          const payload = jwtDecode(jwt) as CustomJWTPayload;
          localStorage.setItem(settings.auth.permissions, JSON.stringify(payload.permissions));
          if (payload.userId) localStorage.setItem(settings.auth.id, payload.userId.toString());
          if (payload.agentId) localStorage.setItem(settings.auth.id, payload.agentId.toString());
        }
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.loginStatus = 'failed';

        if (action.payload) state.loginError = action.payload.message;
        else state.loginError = action.error.message as string;
      });

    builder
      .addCase(logoutAction.pending, state => {
        state.logoutStatus = 'loading';
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.logoutStatus = 'completed';
        state.logoutSuccess = action.payload.message;
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.logoutStatus = 'failed';

        if (action.payload) state.logoutError = action.payload.message;
        else state.logoutError = action.error.message as string;
      });

    builder
      .addCase(createRoleAction.pending, state => {
        state.createRoleStatus = 'loading';
      })
      .addCase(createRoleAction.fulfilled, (state, action) => {
        state.createRoleStatus = 'completed';
        state.createRoleSuccess = action.payload.message;

        if (action.payload.result) state.role = action.payload.result;
      })
      .addCase(createRoleAction.rejected, (state, action) => {
        state.createRoleStatus = 'failed';

        if (action.payload) state.createRoleError = action.payload.message;
        else state.createRoleError = action.error.message as string;
      });

    builder
      .addCase(getRolesAction.pending, state => {
        state.getRolesStatus = 'loading';
      })
      .addCase(getRolesAction.fulfilled, (state, action) => {
        state.getRolesStatus = 'completed';
        state.getRolesSuccess = action.payload.message;

        if (action.payload.results) state.roles = action.payload.results;
      })
      .addCase(getRolesAction.rejected, (state, action) => {
        state.getRolesStatus = 'failed';

        if (action.payload) state.getRolesError = action.payload.message;
        else state.getRolesError = action.error.message as string;
      });

    builder
      .addCase(deleteRoleAction.pending, state => {
        state.deleteRoleStatus = 'loading';
      })
      .addCase(deleteRoleAction.fulfilled, (state, action) => {
        state.deleteRoleStatus = 'completed';
        state.deleteRoleSuccess = action.payload.message;
      })
      .addCase(deleteRoleAction.rejected, (state, action) => {
        state.deleteRoleStatus = 'failed';

        if (action.payload) state.deleteRoleError = action.payload.message;
        else state.deleteRoleError = action.error.message as string;
      });

    builder
      .addCase(editRoleAction.pending, state => {
        state.editRoleStatus = 'loading';
      })
      .addCase(editRoleAction.fulfilled, (state, action) => {
        state.editRoleStatus = 'completed';
        state.editRoleSuccess = action.payload.message;

        if (action.payload.result) state.role = action.payload.result;
      })
      .addCase(editRoleAction.rejected, (state, action) => {
        state.editRoleStatus = 'failed';

        if (action.payload) state.editRoleError = action.payload.message;
        else state.editRoleError = action.error.message as string;
      });

    builder
      .addCase(createUserAction.pending, state => {
        state.createUserStatus = 'loading';
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        state.createUserStatus = 'completed';
        state.createUserSuccess = action.payload.message;

        if (action.payload.result) state.user = action.payload.result;
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.createUserStatus = 'failed';

        if (action.payload) state.createUserError = action.payload.message;
        else state.createUserError = action.error.message as string;
      });

    builder
      .addCase(getUsersAction.pending, state => {
        state.getUsersStatus = 'loading';
      })
      .addCase(getUsersAction.fulfilled, (state, action) => {
        state.getUsersStatus = 'completed';
        state.getUsersSuccess = action.payload.message;

        if (action.payload.results) state.users = action.payload.results;
      })
      .addCase(getUsersAction.rejected, (state, action) => {
        state.getUsersStatus = 'failed';

        if (action.payload) state.getUsersError = action.payload.message;
        else state.getUsersError = action.error.message as string;
      });

    builder
      .addCase(deleteUserAction.pending, state => {
        state.deleteUserStatus = 'loading';
      })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        state.deleteUserStatus = 'completed';
        state.deleteUserSuccess = action.payload.message;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.deleteUserStatus = 'failed';

        if (action.payload) state.deleteUserError = action.payload.message;
        else state.deleteUserError = action.error.message as string;
      });

    builder
      .addCase(editUserAction.pending, state => {
        state.editUserStatus = 'loading';
      })
      .addCase(editUserAction.fulfilled, (state, action) => {
        state.editUserStatus = 'completed';
        state.editUserSuccess = action.payload.message;

        if (action.payload.result) state.user = action.payload.result;
      })
      .addCase(editUserAction.rejected, (state, action) => {
        state.editUserStatus = 'failed';

        if (action.payload) state.editUserError = action.payload.message;
        else state.editUserError = action.error.message as string;
      });

    builder
      .addCase(updateSocketIdAction.pending, state => {
        state.updateSocketIdStatus = 'loading';
      })
      .addCase(updateSocketIdAction.fulfilled, (state, action) => {
        state.updateSocketIdStatus = 'completed';
        state.updateSocketIdSuccess = action.payload.message;

        if (action.payload.result) state.user = action.payload.result;
      })
      .addCase(updateSocketIdAction.rejected, (state, action) => {
        state.updateSocketIdStatus = 'failed';

        if (action.payload) state.updateSocketIdError = action.payload.message;
        else state.updateSocketIdError = action.error.message as string;
      });
  },
});

export const {
  resetGetPermissionsStatus,
  resetLoginStatus,
  resetCreateRoleStatus,
  resetGetRolesStatus,
  resetDeleteRoleStatus,
  resetEditRoleStatus,
  resetCreateUserStatus,
  resetEditUserStatus,
  resetGetUsersStatus,
  resetDeleteUserStatus,
  resetLogoutStatus,
  resetStore,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
