import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IWebRTCConfigSchema } from '@app-interfaces';
import { IWebRTCConfigModel } from '@app-models';

type EditType = {
  id: number;
  data: IWebRTCConfigSchema;
};

const CREATE_WEBRTC_CONFIG = 'telephony:settings:CREATE_WEBRTC_CONFIG';
const EDIT_WEBRTC_CONFIG = 'telephony:settings:EDIT_WEBRTC_CONFIG';
const DELETE_WEBRTC_CONFIG = 'telephony:settings:DELETE_WEBRTC_CONFIG';
const GET_WEBRTC_CONFIGS = 'telephony:settings:GET_WEBRTC_CONFIGS';

const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createWebRTCConfigAction = asyncThunkWrapper<ApiResponseSuccess<IWebRTCConfigModel>, IWebRTCConfigSchema>(
  CREATE_WEBRTC_CONFIG,
  async args => {
    const response = await client.post(`${API_ROOT}/telephony-settings`, args);
    return response.data;
  },
);

export const editWebRTCConfigAction = asyncThunkWrapper<ApiResponseSuccess<IWebRTCConfigModel>, EditType>(
  EDIT_WEBRTC_CONFIG,
  async args => {
    const response = await client.patch(`${API_ROOT}/telephony-settings/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteWebRTCConfigAction = asyncThunkWrapper<ApiResponseSuccess<IWebRTCConfigModel>, number>(
  DELETE_WEBRTC_CONFIG,
  async args => {
    const response = await client.delete(`${API_ROOT}/telephony-settings/${args}`);
    return response.data;
  },
);

export const getWebRTCConfigsAction = asyncThunkWrapper<ApiResponseSuccess<IWebRTCConfigModel>, void>(
  GET_WEBRTC_CONFIGS,
  async () => {
    const response = await client.get(`${API_ROOT}/telephony-settings`);
    return response.data;
  },
);
