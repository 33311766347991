import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IEmailConfigFormSchema } from '@app-interfaces';
import { IEmailConfigModel } from '@app-models';

type EditType = {
  id: number;
  data: IEmailConfigFormSchema;
};

const CREATE_SMTP_CONFIG = 'email:settings:CREATE_SMTP_CONFIG';
const EDIT_SMTP_CONFIG = 'email:settings:EDIT_SMTP_CONFIG';
const DELETE_SMTP_CONFIG = 'email:settings:DELETE_SMTP_CONFIG';

const CREATE_IMAP_CONFIG = 'email:settings:CREATE_IMAP_CONFIG';
const EDIT_IMAP_CONFIG = 'email:settings:EDIT_IMAP_CONFIG';
const DELETE_IMAP_CONFIG = 'email:settings:DELETE_IMAP_CONFIG';

const GET_EMAIL_CONFIGS = 'email:settings:GET_EMAIL_CONFIGS';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createSMTPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, IEmailConfigFormSchema>(
  CREATE_SMTP_CONFIG,
  async args => {
    const response = await client.post(`${API_ROOT}/email-settings`, args);
    return response.data;
  },
);

export const editSMTPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, EditType>(
  EDIT_SMTP_CONFIG,
  async args => {
    const response = await client.patch(`${API_ROOT}/email-settings/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteSMTPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, number>(
  DELETE_SMTP_CONFIG,
  async args => {
    const response = await client.delete(`${API_ROOT}/email-settings/${args}`);
    return response.data;
  },
);

export const createIMAPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, IEmailConfigFormSchema>(
  CREATE_IMAP_CONFIG,
  async args => {
    const response = await client.post(`${API_ROOT}/email-settings`, args);
    return response.data;
  },
);

export const editIMAPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, EditType>(
  EDIT_IMAP_CONFIG,
  async args => {
    const response = await client.patch(`${API_ROOT}/email-settings/${args.id}`, args.data);
    return response.data;
  },
);

export const deleteIMAPConfigAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, number>(
  DELETE_IMAP_CONFIG,
  async args => {
    const response = await client.delete(`${API_ROOT}/email-settings/${args}`);
    return response.data;
  },
);

export const getEmailConfigsAction = asyncThunkWrapper<ApiResponseSuccess<IEmailConfigModel>, void>(
  GET_EMAIL_CONFIGS,
  async () => {
    const response = await client.get(`${API_ROOT}/email-settings`);
    return response.data;
  },
);
