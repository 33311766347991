import { createContext } from 'react';
import { AppContextProps, EmailSettingsContextProps, MailPageContextProps } from '@app-contexts';
import { PureAbility } from '@casl/ability';
import ability from '../helpers/appAbility';

export const AppContext = createContext<AppContextProps | null>(null);

export const EmailSettingsContext = createContext<EmailSettingsContextProps | null>(null);

export const AbilityContext = createContext<PureAbility>(ability);

export const MailPageContext = createContext<MailPageContextProps | null>(null);
