import axios from 'axios';
import { AppSettings } from '@app-interfaces';
import { Http } from '../constants';
import { AxiosClientOptionType } from '@app-types';

export const settings: AppSettings = {
  api: {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    root: process.env.REACT_APP_API_ROOT,
  },
  auth: {
    loginAuth: process.env.REACT_APP_LOGIN_AUTH,
    permissions: process.env.REACT_APP_PERMISSIONS,
    id: process.env.REACT_APP_ID,
  },
  webrtc: { host: process.env.REACT_APP_PBX_HOST, port: process.env.REACT_APP_PBX_WEBRTC_PORT },
};

export const apiClient = (options?: AxiosClientOptionType) => {
  axios.defaults.headers.get['Accept'] = Http.APPLICATION_JSON;
  axios.defaults.headers.post['Content-Type'] = Http.APPLICATION_JSON;
  axios.defaults.headers.patch['Content-Type'] = Http.APPLICATION_JSON;
  axios.defaults.headers.put['Content-Type'] = Http.APPLICATION_JSON;
  axios.defaults.headers.delete['Accept'] = Http.APPLICATION_JSON;
  axios.defaults.baseURL = settings.api.baseURL;

  if (options?.token) {
    const token = localStorage.getItem(settings.auth.loginAuth);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return axios;
  }

  if (options?.axios) {
    return options.axios;
  }

  return axios;
};
