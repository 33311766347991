import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { settings } from '../config';

type Props = {
  children: JSX.Element;
};

function PrivateRoute({ children }: Props) {
  const isSignedIn = localStorage.getItem(settings.auth.loginAuth);

  const location = useLocation();

  return isSignedIn ? children : <Navigate to='/' replace state={{ from: location.pathname }} />;
}

export default PrivateRoute;
