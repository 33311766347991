import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ILoginFormSchema, IRoleFormSchema, IUserFormSchema } from '@app-interfaces';
import { IPermissionModel, IRoleModel, IUserModel } from '@app-models';
import { settings } from '../../config';

const LOGIN = 'authentication:LOGIN';
const LOGOUT = 'authentication:LOGOUT';
const GET_PERMISSIONS = 'authentication:GET_PERMISSIONS';
const CREATE_ROLE = 'authentication:CREATE_ROLE';
const EDIT_ROLE = 'authentication:EDIT_ROLE';
const DELETE_ROLE = 'authentication:DELETE_ROLE';
const GET_ROLES = 'authentication:GET_ROLES';

const CREATE_USER = 'authentication:CREATE_USER';
const EDIT_USER = 'authentication:EDIT_USER';
const DELETE_USER = 'authentication:DELETE_USER';
const GET_USERS = 'authentication:GET_USERS';
const UPDATE_SOCKET_ID = 'authentication:UPDATE_SOCKET_ID';

const API_ROOT = settings.api.root;

export const loginAction = asyncThunkWrapper<ApiResponseSuccess<string>, ILoginFormSchema>(
  LOGIN,
  async (args, axiosClient) => {
    const client = axiosClient();
    const response = await client.post(`${API_ROOT}/login`, args);

    return response.data;
  },
);

export const logoutAction = asyncThunkWrapper<ApiResponseSuccess<string>, void>(LOGOUT, async (_, axiosClient) => {
  const client = axiosClient();
  const response = await client.get(`${API_ROOT}/logout`);

  return response.data;
});

export const getPermissionsAction = asyncThunkWrapper<ApiResponseSuccess<IPermissionModel>, void>(
  GET_PERMISSIONS,
  async (_, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/permissions`);

    return response.data;
  },
);

export const createRoleAction = asyncThunkWrapper<ApiResponseSuccess<IRoleModel>, IRoleFormSchema>(
  CREATE_ROLE,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.post(`${API_ROOT}/roles`, args);

    return response.data;
  },
);

export const getRolesAction = asyncThunkWrapper<ApiResponseSuccess<IRoleModel>, void>(
  GET_ROLES,
  async (_, axiosClient) => {
    const client = axiosClient();
    const response = await client.get(`${API_ROOT}/roles`);

    return response.data;
  },
);

export const deleteRoleAction = asyncThunkWrapper<ApiResponseSuccess<void>, number>(
  DELETE_ROLE,
  async (id, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.delete(`${API_ROOT}/roles/${id}`);

    return response.data;
  },
);

export const editRoleAction = asyncThunkWrapper<ApiResponseSuccess<IRoleModel>, { data: IRoleFormSchema; id: number }>(
  EDIT_ROLE,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.patch(`${API_ROOT}/roles/${args.id}`, args.data);

    return response.data;
  },
);

export const createUserAction = asyncThunkWrapper<ApiResponseSuccess<IUserModel>, IUserFormSchema>(
  CREATE_USER,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.post(`${API_ROOT}/users`, args);

    return response.data;
  },
);

export const getUsersAction = asyncThunkWrapper<ApiResponseSuccess<IUserModel>, void>(
  GET_USERS,
  async (_, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.get(`${API_ROOT}/users`);

    return response.data;
  },
);

export const deleteUserAction = asyncThunkWrapper<ApiResponseSuccess<void>, number>(
  DELETE_USER,
  async (id, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.delete(`${API_ROOT}/users/${id}`);

    return response.data;
  },
);

export const editUserAction = asyncThunkWrapper<ApiResponseSuccess<IUserModel>, { data: IUserFormSchema; id: number }>(
  EDIT_USER,
  async (args, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.patch(`${API_ROOT}/users/${args.id}`, args.data);

    return response.data;
  },
);

export const updateSocketIdAction = asyncThunkWrapper<ApiResponseSuccess<void>, string>(
  UPDATE_SOCKET_ID,
  async (socketId, axiosClient) => {
    const client = axiosClient({ token: true });
    const response = await client.put(`${API_ROOT}/users/update-socket-id`, {
      socketId,
    });

    return response.data;
  },
);
