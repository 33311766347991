import { IThunkStatus } from '@app-types';
import { IEmailConfigModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createIMAPConfigAction,
  createSMTPConfigAction,
  deleteIMAPConfigAction,
  deleteSMTPConfigAction,
  editIMAPConfigAction,
  editSMTPConfigAction,
  getEmailConfigsAction,
} from '../actions/emailConfigActions';

interface IEmailConfigState {
  createSMTPConfigStatus: IThunkStatus;
  createSMTPConfigSuccess: string;
  createSMTPConfigError: string;

  editSMTPConfigStatus: IThunkStatus;
  editSMTPConfigSuccess: string;
  editSMTPConfigError: string;

  deleteSMTPConfigStatus: IThunkStatus;
  deleteSMTPConfigSuccess: string;
  deleteSMTPConfigError: string;

  getEmailConfigsStatus: IThunkStatus;
  getEmailConfigsSuccess: string;
  getEmailConfigsError: string;

  createIMAPConfigStatus: IThunkStatus;
  createIMAPConfigSuccess: string;
  createIMAPConfigError: string;

  editIMAPConfigStatus: IThunkStatus;
  editIMAPConfigSuccess: string;
  editIMAPConfigError: string;

  deleteIMAPConfigStatus: IThunkStatus;
  deleteIMAPConfigSuccess: string;
  deleteIMAPConfigError: string;

  emailConfig: IEmailConfigModel | null;
  emailConfigs: IEmailConfigModel[];
}

const initialState: IEmailConfigState = {
  deleteSMTPConfigError: '',
  deleteSMTPConfigStatus: 'idle',
  deleteSMTPConfigSuccess: '',
  editSMTPConfigError: '',
  editSMTPConfigStatus: 'idle',
  editSMTPConfigSuccess: '',
  getEmailConfigsError: '',
  getEmailConfigsStatus: 'idle',
  getEmailConfigsSuccess: '',
  createSMTPConfigStatus: 'idle',
  createSMTPConfigSuccess: '',
  createSMTPConfigError: '',
  createIMAPConfigStatus: 'idle',
  createIMAPConfigSuccess: '',
  createIMAPConfigError: '',
  deleteIMAPConfigError: '',
  deleteIMAPConfigStatus: 'idle',
  deleteIMAPConfigSuccess: '',
  editIMAPConfigError: '',
  editIMAPConfigStatus: 'idle',
  editIMAPConfigSuccess: '',

  emailConfig: null,
  emailConfigs: [],
};

const emailConfigSlice = createSlice({
  name: 'email:settings',
  initialState,
  reducers: {
    resetCreateSMTPConfigStatus(state: IEmailConfigState) {
      state.createSMTPConfigStatus = 'idle';
      state.createSMTPConfigSuccess = '';
      state.createSMTPConfigSuccess = '';
    },
    resetEditSMTPConfigStatus(state: IEmailConfigState) {
      state.editSMTPConfigStatus = 'idle';
      state.editSMTPConfigSuccess = '';
      state.editSMTPConfigSuccess = '';
    },
    resetDeleteSMTPConfigStatus(state: IEmailConfigState) {
      state.deleteSMTPConfigStatus = 'idle';
      state.deleteSMTPConfigSuccess = '';
      state.deleteSMTPConfigSuccess = '';
    },

    resetCreateIMAPConfigStatus(state: IEmailConfigState) {
      state.createIMAPConfigStatus = 'idle';
      state.createIMAPConfigSuccess = '';
      state.createIMAPConfigSuccess = '';
    },
    resetEditIMAPConfigStatus(state: IEmailConfigState) {
      state.editIMAPConfigStatus = 'idle';
      state.editIMAPConfigSuccess = '';
      state.editIMAPConfigSuccess = '';
    },
    resetDeleteIMAPConfigStatus(state: IEmailConfigState) {
      state.deleteIMAPConfigStatus = 'idle';
      state.deleteIMAPConfigSuccess = '';
      state.deleteIMAPConfigSuccess = '';
    },

    resetGetSMTPConfigsStatus(state: IEmailConfigState) {
      state.getEmailConfigsStatus = 'idle';
      state.getEmailConfigsSuccess = '';
      state.getEmailConfigsSuccess = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createSMTPConfigAction.pending, state => {
        state.createSMTPConfigStatus = 'loading';
      })
      .addCase(createSMTPConfigAction.fulfilled, (state, action) => {
        state.createSMTPConfigStatus = 'completed';
        state.createSMTPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          state.emailConfig = action.payload.result;

          state.emailConfigs.push(action.payload.result);
        }
      })
      .addCase(createSMTPConfigAction.rejected, (state, action) => {
        state.createSMTPConfigStatus = 'failed';

        if (action.payload) {
          state.createSMTPConfigError = action.payload.message;
        } else state.createSMTPConfigError = action.error.message as string;
      })

      .addCase(editSMTPConfigAction.pending, state => {
        state.editSMTPConfigStatus = 'loading';
      })
      .addCase(editSMTPConfigAction.fulfilled, (state, action) => {
        state.editSMTPConfigStatus = 'completed';
        state.editSMTPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.emailConfigs];

          state.emailConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.emailConfigs.splice(index, 1, config);
          }
        }
      })
      .addCase(editSMTPConfigAction.rejected, (state, action) => {
        state.editSMTPConfigStatus = 'failed';

        if (action.payload) {
          state.editSMTPConfigError = action.payload.message;
        } else state.editSMTPConfigError = action.error.message as string;
      })

      .addCase(deleteSMTPConfigAction.pending, state => {
        state.deleteSMTPConfigStatus = 'loading';
      })
      .addCase(deleteSMTPConfigAction.fulfilled, (state, action) => {
        state.deleteSMTPConfigStatus = 'completed';
        state.deleteSMTPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.emailConfigs];

          state.emailConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.emailConfigs.splice(index, 1);
          }
        }
      })
      .addCase(deleteSMTPConfigAction.rejected, (state, action) => {
        state.deleteSMTPConfigStatus = 'failed';

        if (action.payload) {
          state.deleteSMTPConfigError = action.payload.message;
        } else state.deleteSMTPConfigError = action.error.message as string;
      });

    builder
      .addCase(createIMAPConfigAction.pending, state => {
        state.createIMAPConfigStatus = 'loading';
      })
      .addCase(createIMAPConfigAction.fulfilled, (state, action) => {
        state.createIMAPConfigStatus = 'completed';
        state.createIMAPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          state.emailConfig = action.payload.result;

          state.emailConfigs.push(action.payload.result);
        }
      })
      .addCase(createIMAPConfigAction.rejected, (state, action) => {
        state.createIMAPConfigStatus = 'failed';

        if (action.payload) {
          state.createIMAPConfigError = action.payload.message;
        } else state.createIMAPConfigError = action.error.message as string;
      })

      .addCase(editIMAPConfigAction.pending, state => {
        state.editIMAPConfigStatus = 'loading';
      })
      .addCase(editIMAPConfigAction.fulfilled, (state, action) => {
        state.editIMAPConfigStatus = 'completed';
        state.editIMAPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.emailConfigs];

          state.emailConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.emailConfigs.splice(index, 1, config);
          }
        }
      })
      .addCase(editIMAPConfigAction.rejected, (state, action) => {
        state.editIMAPConfigStatus = 'failed';

        if (action.payload) {
          state.editIMAPConfigError = action.payload.message;
        } else state.editIMAPConfigError = action.error.message as string;
      })

      .addCase(deleteIMAPConfigAction.pending, state => {
        state.deleteIMAPConfigStatus = 'loading';
      })
      .addCase(deleteIMAPConfigAction.fulfilled, (state, action) => {
        state.deleteIMAPConfigStatus = 'completed';
        state.deleteIMAPConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.emailConfigs];

          state.emailConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.emailConfigs.splice(index, 1);
          }
        }
      })
      .addCase(deleteIMAPConfigAction.rejected, (state, action) => {
        state.deleteIMAPConfigStatus = 'failed';

        if (action.payload) {
          state.deleteIMAPConfigError = action.payload.message;
        } else state.deleteIMAPConfigError = action.error.message as string;
      });

    builder
      .addCase(getEmailConfigsAction.pending, state => {
        state.getEmailConfigsStatus = 'loading';
      })
      .addCase(getEmailConfigsAction.fulfilled, (state, action) => {
        state.getEmailConfigsStatus = 'completed';
        state.getEmailConfigsSuccess = action.payload.message;

        if (action.payload.results) state.emailConfigs = action.payload.results;
      })
      .addCase(getEmailConfigsAction.rejected, (state, action) => {
        state.getEmailConfigsStatus = 'failed';

        if (action.payload) {
          state.getEmailConfigsError = action.payload.message;
        } else state.getEmailConfigsError = action.error.message as string;
      });
  },
});

export const {
  resetCreateSMTPConfigStatus,
  resetCreateIMAPConfigStatus,
  resetGetSMTPConfigsStatus,
  resetEditSMTPConfigStatus,
  resetDeleteSMTPConfigStatus,
  resetEditIMAPConfigStatus,
  resetDeleteIMAPConfigStatus,
} = emailConfigSlice.actions;
export default emailConfigSlice.reducer;
