import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ICustomerFormSchema } from '@app-interfaces';
import { ICustomerModel } from '@app-models';

interface EditeProps {
  id: number;
  data: ICustomerFormSchema;
}

const CUSTOMERS_EMAILS = 'customers:CUSTOMERS_EMAILS';
const GET_CUSTOMERS = 'customers:GET_CUSTOMERS';
const CREATE_CUSTOMER = 'customers:CREATE_CUSTOMER';
const EDIT_CUSTOMER = 'customers:EDIT_CUSTOMER';
const DELETE_CUSTOMER = 'customers:DELETE_CUSTOMER';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const getCustomersEmailsAction = asyncThunkWrapper<ApiResponseSuccess<ICustomerModel>, void>(
  CUSTOMERS_EMAILS,
  async () => {
    const response = await client.get(`${API_ROOT}/customers/emails`);

    return response.data;
  },
);

export const getCustomersAction = asyncThunkWrapper<ApiResponseSuccess<ICustomerModel>, void>(
  GET_CUSTOMERS,
  async () => {
    const response = await client.get(`${API_ROOT}/customers`);

    return response.data;
  },
);

export const createCustomerAction = asyncThunkWrapper<ApiResponseSuccess<ICustomerModel>, ICustomerFormSchema>(
  CREATE_CUSTOMER,
  async args => {
    const response = await client.post(`${API_ROOT}/customers`, args);

    return response.data;
  },
);

export const editCustomerAction = asyncThunkWrapper<ApiResponseSuccess<ICustomerModel>, EditeProps>(
  EDIT_CUSTOMER,
  async args => {
    const response = await client.patch(`${API_ROOT}/customers/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCustomerAction = asyncThunkWrapper<ApiResponseSuccess<ICustomerModel>, number>(
  DELETE_CUSTOMER,
  async args => {
    const response = await client.delete(`${API_ROOT}/customers/${args}`);

    return response.data;
  },
);
