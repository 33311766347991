import { IThunkStatus } from '@app-types';
import { ICustomerModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createCustomerAction,
  deleteCustomerAction,
  editCustomerAction,
  getCustomersAction,
  getCustomersEmailsAction,
} from '../actions/customerActions';

interface ICustomerState {
  getCustomersEmailsStatus: IThunkStatus;
  getCustomersEmailsSuccess: string;
  getCustomersEmailsError: string;
  getCustomersStatus: IThunkStatus;
  getCustomersSuccess: string;
  getCustomersError: string;
  createCustomerStatus: IThunkStatus;
  createCustomerSuccess: string;
  createCustomerError: string;
  editCustomerStatus: IThunkStatus;
  editCustomerSuccess: string;
  editCustomerError: string;
  deleteCustomerStatus: IThunkStatus;
  deleteCustomerSuccess: string;
  deleteCustomerError: string;

  customers: ICustomerModel[];
  customerEmails: { email: string }[];
  customer: ICustomerModel | null;
}

const initialState: ICustomerState = {
  getCustomersEmailsStatus: 'idle',
  getCustomersEmailsSuccess: '',
  getCustomersEmailsError: '',

  getCustomersStatus: 'idle',
  getCustomersSuccess: '',
  getCustomersError: '',

  createCustomerStatus: 'idle',
  createCustomerSuccess: '',
  createCustomerError: '',

  editCustomerStatus: 'idle',
  editCustomerSuccess: '',
  editCustomerError: '',

  deleteCustomerStatus: 'idle',
  deleteCustomerSuccess: '',
  deleteCustomerError: '',

  customerEmails: [],
  customers: [],
  customer: null,
};

const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetGetCustomersEmailsStatus(state: ICustomerState) {
      state.getCustomersEmailsStatus = 'idle';
      state.getCustomersEmailsSuccess = '';
      state.getCustomersEmailsError = '';
    },
    resetGetCustomersStatus(state: ICustomerState) {
      state.getCustomersStatus = 'idle';
      state.getCustomersSuccess = '';
      state.getCustomersError = '';
    },
    resetCreateCustomerStatus(state: ICustomerState) {
      state.createCustomerStatus = 'idle';
      state.createCustomerSuccess = '';
      state.createCustomerError = '';
    },
    resetEditCustomerStatus(state: ICustomerState) {
      state.editCustomerStatus = 'idle';
      state.editCustomerSuccess = '';
      state.editCustomerError = '';
    },
    resetDeleteCustomerStatus(state: ICustomerState) {
      state.deleteCustomerStatus = 'idle';
      state.deleteCustomerSuccess = '';
      state.deleteCustomerError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCustomersEmailsAction.pending, state => {
        state.getCustomersEmailsStatus = 'loading';
      })
      .addCase(getCustomersEmailsAction.fulfilled, (state, action) => {
        state.getCustomersEmailsStatus = 'completed';
        state.getCustomersEmailsSuccess = action.payload.message;
        if (action.payload && action.payload.results) state.customerEmails = action.payload.results;
      })
      .addCase(getCustomersEmailsAction.rejected, (state, action) => {
        state.getCustomersEmailsStatus = 'failed';

        if (action.payload) {
          state.getCustomersEmailsError = action.payload.message;
        } else {
          state.getCustomersEmailsError = action.error.message as string;
        }
      });

    builder
      .addCase(getCustomersAction.pending, state => {
        state.getCustomersStatus = 'loading';
      })
      .addCase(getCustomersAction.fulfilled, (state, action) => {
        state.getCustomersStatus = 'completed';
        state.getCustomersSuccess = action.payload.message;
        if (action.payload && action.payload.results) state.customers = action.payload.results;
      })
      .addCase(getCustomersAction.rejected, (state, action) => {
        state.getCustomersStatus = 'failed';

        if (action.payload) {
          state.getCustomersError = action.payload.message;
        } else {
          state.getCustomersError = action.error.message as string;
        }
      });

    builder
      .addCase(createCustomerAction.pending, state => {
        state.createCustomerStatus = 'loading';
      })
      .addCase(createCustomerAction.fulfilled, (state, action) => {
        state.createCustomerStatus = 'completed';
        state.createCustomerSuccess = action.payload.message;
        if (action.payload && action.payload.result) state.customers.push(action.payload.result);
      })
      .addCase(createCustomerAction.rejected, (state, action) => {
        state.createCustomerStatus = 'failed';

        if (action.payload) {
          state.createCustomerError = action.payload.message;
        } else {
          state.createCustomerError = action.error.message as string;
        }
      });

    builder
      .addCase(editCustomerAction.pending, state => {
        state.editCustomerStatus = 'loading';
      })
      .addCase(editCustomerAction.fulfilled, (state, action) => {
        state.editCustomerStatus = 'completed';
        state.editCustomerSuccess = action.payload.message;
        if (action.payload && action.payload.result) {
          const customers = [...state.customers];
          const currentCustomer = action.payload.result;

          const index = customers.findIndex(value => value.id === currentCustomer.id);

          if (index !== -1) state.customers.splice(index, 1, currentCustomer);
        }
      })
      .addCase(editCustomerAction.rejected, (state, action) => {
        state.editCustomerStatus = 'failed';

        if (action.payload) {
          state.editCustomerError = action.payload.message;
        } else {
          state.editCustomerError = action.error.message as string;
        }
      });

    builder
      .addCase(deleteCustomerAction.pending, state => {
        state.deleteCustomerStatus = 'loading';
      })
      .addCase(deleteCustomerAction.fulfilled, (state, action) => {
        state.deleteCustomerStatus = 'completed';
        state.deleteCustomerSuccess = action.payload.message;
        if (action.payload && action.payload.result) {
          const customers = [...state.customers];
          const currentCustomer = action.payload.result;

          const index = customers.findIndex(value => value.id === currentCustomer.id);

          if (index !== -1) state.customers.splice(index, 1);
        }
      })
      .addCase(deleteCustomerAction.rejected, (state, action) => {
        state.deleteCustomerStatus = 'failed';

        if (action.payload) {
          state.deleteCustomerError = action.payload.message;
        } else {
          state.deleteCustomerError = action.error.message as string;
        }
      });
  },
});

export const {
  resetGetCustomersEmailsStatus,
  resetGetCustomersStatus,
  resetDeleteCustomerStatus,
  resetEditCustomerStatus,
  resetCreateCustomerStatus,
} = customerSlice.actions;
export default customerSlice.reducer;
