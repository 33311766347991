import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IQAListFormSchema } from '@app-interfaces';
import { IQAFormModel } from '@app-models';
import { QAFormBuilderType } from '@app-types';

const CREATE_QUALITY_ASSURANCE = 'QA:CREATE_QUALITY_ASSURANCE';
const EDIT_QUALITY_ASSURANCE = 'QA:EDIT_QUALITY_ASSURANCE';
const DELETE_QUALITY_ASSURANCE = 'QA:DELETE_QUALITY_ASSURANCE';
const GET_QUALITY_ASSURANCES = 'QA:GET_QUALITY_ASSURANCES';
const GET_QUALITY_ASSURANCE = 'QA:GET_QUALITY_ASSURANCE';
const BUILD_QUALITY_ASSURANCE_FORM = 'QA:BUILD_QUALITY_ASSURANCE_FORM';

const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createQAAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, IQAListFormSchema>(
  CREATE_QUALITY_ASSURANCE,
  async args => {
    const response = await client.post(`${API_ROOT}/quality-assurance/forms`, args);

    return response.data;
  },
);

interface IEditType {
  id: number;
  data: IQAListFormSchema;
}

export const editQAAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, IEditType>(
  EDIT_QUALITY_ASSURANCE,
  async args => {
    const response = await client.patch(`${API_ROOT}/quality-assurance/forms/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteQAAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, number>(
  DELETE_QUALITY_ASSURANCE,
  async args => {
    const response = await client.delete(`${API_ROOT}/quality-assurance/forms/${args}`);

    return response.data;
  },
);

export const getQASAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, void>(
  GET_QUALITY_ASSURANCES,
  async () => {
    const response = await client.get(`${API_ROOT}/quality-assurance/forms`);

    return response.data;
  },
);

export const getQAAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, number>(
  GET_QUALITY_ASSURANCE,
  async args => {
    const response = await client.get(`${API_ROOT}/quality-assurance/forms/${args}`);

    return response.data;
  },
);

interface IBuildForm {
  id: number;
  data: QAFormBuilderType;
}

export const buildQAFormAction = asyncThunkWrapper<ApiResponseSuccess<IQAFormModel>, IBuildForm>(
  BUILD_QUALITY_ASSURANCE_FORM,
  async args => {
    const response = await client.patch(`${API_ROOT}/quality-assurance/build-form/${args.id}`, args.data);

    return response.data;
  },
);
