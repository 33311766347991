export default async function initFacebookSdk() {
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];

    if (d.getElementById(id)) return;

    const js = d.createElement(s) as HTMLScriptElement;

    js.id = id;

    js.src = 'https://connect.facebook.net/en_US/sdk.js';

    if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}
