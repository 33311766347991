import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ICaseReportFilterSchema } from '@app-interfaces';
import { ICaseModel } from '@app-models';

const FILTER_CASES_BY_DATE = 'reports:cases:FILTER_CASES_BY_DATE';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const filterCasesByDateAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, ICaseReportFilterSchema>(
  FILTER_CASES_BY_DATE,
  async args => {
    const response = await client.post(`${API_ROOT}/reports/cases`, args);

    return response.data;
  },
);
