import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

type Props = {
  children?: ReactNode;
};
export default function PublicLayout({ children }: Props): JSX.Element {
  return (
    <Box
      sx={{
        maxHeight: '100vh',
        height: '100%',
        overflow: 'auto',
      }}>
      {children}
    </Box>
  );
}
