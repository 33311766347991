import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from './authenticationReducer';
import agentManagementReducer from './agentManagementReducer';
import caseFormReducer from './caseFormReducer';
import caseSettingsReducer from './caseSettingsReducer';
import caseReducer from './caseReducer';
import emailConfigReducer from './emailConfigReducer';
import socketReducer from './socketReducer';
import customerReducer from './customerReducer';
import emailReducer from './emailReducer';
import dashboardReducer from './dashboardReducer';
import webRTCReducer from './webRTCReducer';
import qualityAssuranceReducer from './qualityAssuranceReducer';
import evaluationReducer from './evaluationReducer';
import caseReportsReducer from './caseReportsReducer';
import facebookConfigReducer from './facebookConfigReducer';
import facebookReducer from './facebookReducer';
import telephonyConfigReducer from './telephonyConfigReducer';

const reducers = combineReducers({
  authenticationReducer,
  agentManagementReducer,
  caseFormReducer,
  caseSettingsReducer,
  caseReducer,
  emailConfigReducer,
  socketReducer,
  customerReducer,
  emailReducer,
  dashboardReducer,
  webRTCReducer,
  qualityAssuranceReducer,
  evaluationReducer,
  caseReportsReducer,
  facebookConfigReducer,
  facebookReducer,
  telephonyConfigReducer,
});

const rootReducer = (state: ReturnType<typeof reducers> | undefined, action: AnyAction) => {
  if (action.type === 'authentication/resetStore') {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

export default rootReducer;
