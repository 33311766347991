import { IThunkStatus } from '@app-types';
import { IQAFormModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  buildQAFormAction,
  createQAAction,
  deleteQAAction,
  editQAAction,
  getQAAction,
  getQASAction,
} from '../actions/qualityAssuranceActions';

interface IQualityAssuranceState {
  createQAStatus: IThunkStatus;
  createQASuccess: string;
  createQAError: string;
  editQAStatus: IThunkStatus;
  editQASuccess: string;
  editQAError: string;
  deleteQAStatus: IThunkStatus;
  deleteQASuccess: string;
  deleteQAError: string;
  getQAsStatus: IThunkStatus;
  getQAsSuccess: string;
  getQAsError: string;
  getQAStatus: IThunkStatus;
  getQASuccess: string;
  getQAError: string;
  buildQAFormStatus: IThunkStatus;
  buildQAFormSuccess: string;
  buildQAFormError: string;
  qualityAssurance: IQAFormModel | null;
  qualityAssurances: IQAFormModel[];
}

const initialState: IQualityAssuranceState = {
  createQAStatus: 'idle',
  createQASuccess: '',
  createQAError: '',
  editQAStatus: 'idle',
  editQASuccess: '',
  editQAError: '',
  deleteQAStatus: 'idle',
  deleteQASuccess: '',
  deleteQAError: '',
  getQAsStatus: 'idle',
  getQAsSuccess: '',
  getQAsError: '',
  getQAStatus: 'idle',
  getQASuccess: '',
  getQAError: '',
  buildQAFormStatus: 'idle',
  buildQAFormSuccess: '',
  buildQAFormError: '',

  qualityAssurance: null,
  qualityAssurances: [],
};

const qualityAssuranceSlice = createSlice({
  name: 'QA',
  initialState,
  reducers: {
    resetCreateQAStatus(state: IQualityAssuranceState) {
      state.createQAStatus = 'idle';
      state.createQASuccess = '';
      state.createQAError = '';
    },
    resetEditQAStatus(state: IQualityAssuranceState) {
      state.editQAStatus = 'idle';
      state.editQASuccess = '';
      state.editQAError = '';
    },
    resetDeleteQAStatus(state: IQualityAssuranceState) {
      state.deleteQAStatus = 'idle';
      state.deleteQASuccess = '';
      state.deleteQAError = '';
    },
    resetGetQASStatus(state: IQualityAssuranceState) {
      state.getQAsStatus = 'idle';
      state.getQAsSuccess = '';
      state.getQAsError = '';
    },
    resetGetQAStatus(state: IQualityAssuranceState) {
      state.getQAStatus = 'idle';
      state.getQASuccess = '';
      state.getQAError = '';
    },
    resetBuildQAFormStatus(state: IQualityAssuranceState) {
      state.buildQAFormStatus = 'idle';
      state.buildQAFormSuccess = '';
      state.buildQAFormError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createQAAction.pending, state => {
        state.createQAStatus = 'loading';
      })
      .addCase(createQAAction.fulfilled, (state, action) => {
        state.createQAStatus = 'completed';
        state.createQASuccess = action.payload.message;
        state.qualityAssurance = action.payload.result as IQAFormModel;
        state.qualityAssurances.push(state.qualityAssurance);
      })
      .addCase(createQAAction.rejected, (state, action) => {
        state.createQAStatus = 'failed';
        state.createQAError = action.payload ? action.payload.message : (action.error.message as string);
      });

    builder
      .addCase(editQAAction.pending, state => {
        state.editQAStatus = 'loading';
      })
      .addCase(editQAAction.fulfilled, (state, action) => {
        state.editQAStatus = 'completed';
        state.editQASuccess = action.payload.message;

        const currQA = action.payload.result as IQAFormModel;
        const prevQAIndex = state.qualityAssurances.findIndex(item => item.id === currQA.id);

        if (prevQAIndex) state.qualityAssurances.splice(prevQAIndex, 1, currQA);
      })
      .addCase(editQAAction.rejected, (state, action) => {
        state.editQAStatus = 'failed';
        state.editQAError = action.payload ? action.payload.message : (action.error.message as string);
      });

    builder
      .addCase(deleteQAAction.pending, state => {
        state.deleteQAStatus = 'loading';
      })
      .addCase(deleteQAAction.fulfilled, (state, action) => {
        state.deleteQAStatus = 'completed';
        state.deleteQASuccess = action.payload.message;
        const currQA = action.payload.result as IQAFormModel;
        const prevQAIndex = state.qualityAssurances.findIndex(item => item.id === currQA.id);

        if (prevQAIndex) state.qualityAssurances.splice(prevQAIndex, 1);
      })
      .addCase(deleteQAAction.rejected, (state, action) => {
        state.deleteQAStatus = 'failed';
        state.deleteQAError = action.payload ? action.payload.message : (action.error.message as string);
      });

    builder
      .addCase(getQASAction.pending, state => {
        state.getQAsStatus = 'loading';
      })
      .addCase(getQASAction.fulfilled, (state, action) => {
        state.getQAsStatus = 'completed';
        state.getQAsSuccess = action.payload.message;
        state.qualityAssurances = action.payload.results as IQAFormModel[];
      })
      .addCase(getQASAction.rejected, (state, action) => {
        state.getQAsStatus = 'failed';
        state.getQAsError = action.payload ? action.payload.message : (action.error.message as string);
      });

    builder
      .addCase(getQAAction.pending, state => {
        state.getQAStatus = 'loading';
      })
      .addCase(getQAAction.fulfilled, (state, action) => {
        state.getQAStatus = 'completed';
        state.getQASuccess = action.payload.message;
        state.qualityAssurance = action.payload.result as IQAFormModel;
      })
      .addCase(getQAAction.rejected, (state, action) => {
        state.getQAStatus = 'failed';
        state.getQAError = action.payload ? action.payload.message : (action.error.message as string);
      });

    builder
      .addCase(buildQAFormAction.pending, state => {
        state.buildQAFormStatus = 'loading';
      })
      .addCase(buildQAFormAction.fulfilled, (state, action) => {
        state.buildQAFormStatus = 'completed';
        state.buildQAFormSuccess = action.payload.message;
        state.qualityAssurance = action.payload.result as IQAFormModel;
      })
      .addCase(buildQAFormAction.rejected, (state, action) => {
        state.buildQAFormStatus = 'failed';
        state.buildQAFormError = action.payload ? action.payload.message : (action.error.message as string);
      });
  },
});

export const {
  resetGetQASStatus,
  resetDeleteQAStatus,
  resetEditQAStatus,
  resetCreateQAStatus,
  resetBuildQAFormStatus,
  resetGetQAStatus,
} = qualityAssuranceSlice.actions;

export default qualityAssuranceSlice.reducer;
