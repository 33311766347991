import React, { Suspense, useState } from 'react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import useAppTheme from './hooks/useAppTheme';
import { AppContext } from './contexts';
import { AppRoutesWrapper } from './routes';
import { CssBaseline } from '@mui/material';
import AppLoader from './components/AppLoader';
import ChartStyle from './components/chart/ChartStyle';

function App() {
  const [openSideNav, setOpenSideNav] = useState<boolean>(true);

  const theme = useAppTheme();

  return (
    <AppContext.Provider value={{ openSideNav, setOpenSideNav }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <ChartStyle />
          <Suspense fallback={<AppLoader show={true} />}>
            <AppRoutesWrapper />
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContext.Provider>
  );
}

export default App;
