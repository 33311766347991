import React, { forwardRef, ReactNode, useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { LeftSideNavType, RightSideNavType } from '@app-types';
import { leftSideNavs, rightSideNavs } from '../routes';

interface Props {
  children: ReactNode;
  title?: string;
  meta?: ReactNode;
  showEdit?: boolean;
}

type RefProps = HTMLDivElement;

const Page = forwardRef<RefProps, Props>(({ children, title = '', meta, ...other }, ref) => {
  const location = useLocation();

  const getHeader = useMemo(() => {
    let header = '';

    const handleGetHeader = (navs: (LeftSideNavType | RightSideNavType)[]) => {
      navs.forEach(parent => {
        const pathname = parent.path;

        if (location.pathname === pathname) header = parent.name;

        if (parent.children) {
          parent.children.forEach(child => {
            const pathname = `${parent.path}${child.path}`;

            if (location.pathname === pathname) {
              header = child.name;
            }
          });
        }
      });
    };

    handleGetHeader(leftSideNavs);

    if (!header) handleGetHeader(rightSideNavs);

    return header + ' |';
  }, [location]);

  const getTitle = useMemo(() => {
    return title ? title + ' |' : getHeader;
  }, [getHeader, title]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${getTitle} UrCRM`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </React.Fragment>
  );
});

Page.displayName = 'Page';
export default Page;
