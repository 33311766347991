import { IThunkStatus } from '@app-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initSipClientAction } from '../actions/webRTCActions';
import * as JsSip from 'jssip';
import { settings } from '../../config';
import { CallOptions } from 'jssip/lib/UA';

interface IWebRTCState {
  initSipClientStatus: IThunkStatus;
  initSipClientSuccess: string;
  initSipClientError: string;

  sipWebRTCInterface: JsSip.Socket | null;
  sipUA: JsSip.UA | null;
  mediaStream?: MediaStreamTrack;
  sdpType?: 'offer' | 'answer';
}

const initialState: IWebRTCState = {
  initSipClientStatus: 'idle',
  initSipClientSuccess: '',
  initSipClientError: '',
  sipUA: null,
  sipWebRTCInterface: null,
};

const webRTCSlice = createSlice({
  name: 'webrtc',
  initialState,
  reducers: {
    setMediaPayload(state: IWebRTCState, action: PayloadAction<{ mediaStream: MediaStreamTrack }>) {
      state.mediaStream = action.payload.mediaStream;
    },

    setSipUA(state: IWebRTCState, action: PayloadAction<{ username: string }>) {
      if (!state.sipUA && state.sipWebRTCInterface) {
        const ua = new JsSip.UA({
          sockets: [state.sipWebRTCInterface],
          uri: `sip:${action.payload.username}@${settings.webrtc?.host}`,
          register: true,
          authorization_user: action.payload.username,
        });

        ua.start();

        state.sipUA = ua;
      }
    },

    makeAudioCall(state: IWebRTCState, action: PayloadAction<{ phone: string }>) {
      if (state.sipUA) {
        const options: CallOptions = {
          mediaConstraints: {
            audio: true,
            video: false,
          },
          pcConfig: {
            iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
            iceTransportPolicy: 'all',
            rtcpMuxPolicy: 'require',
          },
          fromUserName: '100',
          fromDisplayName: '100',
        };

        const phone = `sip:${action.payload.phone}@${settings.webrtc?.host}`;

        state.sipUA.call(phone, options);
      }
    },

    stopSipUA(state: IWebRTCState) {
      if (state.sipUA && state.sipUA.isRegistered()) {
        state.sipUA.stop();
      }
    },

    resetInitSipClientStatus(state: IWebRTCState) {
      state.initSipClientStatus = 'idle';
      state.initSipClientSuccess = '';
      state.initSipClientError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initSipClientAction.pending, state => {
        state.initSipClientStatus = 'loading';
      })
      .addCase(initSipClientAction.fulfilled, (state, action) => {
        state.initSipClientStatus = 'completed';
        state.sipWebRTCInterface = action.payload;
      })
      .addCase(initSipClientAction.rejected, (state, action) => {
        state.initSipClientStatus = 'failed';
        state.initSipClientError = action.error.message as string;
      });
  },
});

export const { resetInitSipClientStatus, stopSipUA, setSipUA, makeAudioCall, setMediaPayload } = webRTCSlice.actions;
export default webRTCSlice.reducer;
