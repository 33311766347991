import { useMemo } from 'react';
import { createTheme, responsiveFontSizes, useMediaQuery } from '@mui/material';

import componentsOverride from '../theme/overrides';
import palette from '../theme/palette';
import typography from '../theme/typography';
import shadows, { customShadows } from '../theme/shadows';
import { Shadows } from '@mui/material/styles';

export default function useAppTheme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createTheme({
        palette: prefersDarkMode ? palette.dark : palette.light,
        typography,
        customShadows: prefersDarkMode ? customShadows.light : customShadows.dark,
        shadows: prefersDarkMode ? (shadows.light as Shadows) : (shadows.dark as Shadows),
        shape: { borderRadius: 8 },
      }),
    [prefersDarkMode]
  );

  responsiveFontSizes(theme);
  theme.components = componentsOverride(theme);

  return theme;
}
