import { IThunkStatus } from '@app-types';
import { IAgentGroupModel, IAgentModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createAgentAction,
  createAgentGroupAction,
  deleteAgentAction,
  deleteAgentGroupAction,
  editAgentAction,
  editAgentGroupAction,
  getAgentAction,
  getAgentGroupAction,
  getAgentGroupsAction,
  getAgentsAction,
} from '../actions/agentManagementActions';

interface IAgentManagementState {
  createAgentStatus: IThunkStatus;
  createAgentSuccess: string;
  createAgentError: string;

  editAgentStatus: IThunkStatus;
  editAgentSuccess: string;
  editAgentError: string;

  deleteAgentStatus: IThunkStatus;
  deleteAgentSuccess: string;
  deleteAgentError: string;

  getAgentStatus: IThunkStatus;
  getAgentSuccess: string;
  getAgentError: string;

  getAgentsStatus: IThunkStatus;
  getAgentsSuccess: string;
  getAgentsError: string;

  createAgentGroupStatus: IThunkStatus;
  createAgentGroupSuccess: string;
  createAgentGroupError: string;

  editAgentGroupStatus: IThunkStatus;
  editAgentGroupSuccess: string;
  editAgentGroupError: string;

  deleteAgentGroupStatus: IThunkStatus;
  deleteAgentGroupSuccess: string;
  deleteAgentGroupError: string;

  getAgentGroupStatus: IThunkStatus;
  getAgentGroupSuccess: string;
  getAgentGroupError: string;

  getAgentGroupsStatus: IThunkStatus;
  getAgentGroupsSuccess: string;
  getAgentGroupsError: string;

  agent: IAgentModel | null;
  group: IAgentGroupModel | null;
  agents: IAgentModel[];
  groups: IAgentGroupModel[];
}

const initialState: IAgentManagementState = {
  createAgentStatus: 'idle',
  createAgentError: '',
  createAgentSuccess: '',

  editAgentStatus: 'idle',
  editAgentError: '',
  editAgentSuccess: '',

  deleteAgentStatus: 'idle',
  deleteAgentError: '',
  deleteAgentSuccess: '',

  getAgentStatus: 'idle',
  getAgentError: '',
  getAgentSuccess: '',

  getAgentsStatus: 'idle',
  getAgentsError: '',
  getAgentsSuccess: '',

  createAgentGroupStatus: 'idle',
  createAgentGroupError: '',
  createAgentGroupSuccess: '',

  editAgentGroupStatus: 'idle',
  editAgentGroupError: '',
  editAgentGroupSuccess: '',

  deleteAgentGroupStatus: 'idle',
  deleteAgentGroupError: '',
  deleteAgentGroupSuccess: '',

  getAgentGroupStatus: 'idle',
  getAgentGroupError: '',
  getAgentGroupSuccess: '',

  getAgentGroupsStatus: 'idle',
  getAgentGroupsError: '',
  getAgentGroupsSuccess: '',

  agent: null,
  group: null,
  agents: [],
  groups: [],
};

const agentManagementSlice = createSlice({
  name: 'agent-mgt',
  initialState,
  reducers: {
    resetCreateAgentStatus(state: IAgentManagementState) {
      state.createAgentStatus = 'idle';
      state.createAgentSuccess = '';
      state.createAgentError = '';
    },
    resetEditAgentStatus(state: IAgentManagementState) {
      state.editAgentStatus = 'idle';
      state.editAgentSuccess = '';
      state.editAgentError = '';
    },
    resetDeleteAgentStatus(state: IAgentManagementState) {
      state.deleteAgentStatus = 'idle';
      state.deleteAgentSuccess = '';
      state.deleteAgentError = '';
    },
    resetGetAgentStatus(state: IAgentManagementState) {
      state.getAgentStatus = 'idle';
      state.getAgentSuccess = '';
      state.getAgentError = '';
    },
    resetGetAgentsStatus(state: IAgentManagementState) {
      state.getAgentsStatus = 'idle';
      state.getAgentsSuccess = '';
      state.getAgentsError = '';
    },

    resetCreateAgentGroupStatus(state: IAgentManagementState) {
      state.createAgentGroupStatus = 'idle';
      state.createAgentGroupSuccess = '';
      state.createAgentGroupError = '';
    },
    resetEditAgentGroupStatus(state: IAgentManagementState) {
      state.editAgentGroupStatus = 'idle';
      state.editAgentGroupSuccess = '';
      state.editAgentGroupError = '';
    },
    resetDeleteAgentGroupStatus(state: IAgentManagementState) {
      state.deleteAgentGroupStatus = 'idle';
      state.deleteAgentGroupSuccess = '';
      state.deleteAgentGroupError = '';
    },
    resetGetAgentGroupStatus(state: IAgentManagementState) {
      state.getAgentGroupStatus = 'idle';
      state.getAgentGroupSuccess = '';
      state.getAgentGroupError = '';
    },
    resetGetAgentGroupsStatus(state: IAgentManagementState) {
      state.getAgentGroupsStatus = 'idle';
      state.getAgentGroupsSuccess = '';
      state.getAgentGroupsError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAgentAction.pending, state => {
        state.createAgentStatus = 'loading';
      })
      .addCase(createAgentAction.fulfilled, (state, action) => {
        state.createAgentStatus = 'completed';
        state.createAgentSuccess = action.payload.message;

        if (action.payload.result) {
          state.agent = action.payload.result;

          state.agents.push(state.agent);
        }
      })
      .addCase(createAgentAction.rejected, (state, action) => {
        state.createAgentStatus = 'failed';

        if (action.payload) state.createAgentError = action.payload.message;
        else state.createAgentError = action.error.message as string;
      });

    builder
      .addCase(editAgentAction.pending, state => {
        state.editAgentStatus = 'loading';
      })
      .addCase(editAgentAction.fulfilled, (state, action) => {
        state.editAgentStatus = 'completed';
        state.editAgentSuccess = action.payload.message;

        if (action.payload.result) {
          const tempAgent = action.payload.result;
          const tempAgents = [...state.agents];

          const agent = tempAgents.find(agent => agent.id === tempAgent.id);

          if (agent) {
            const index = tempAgents.indexOf(agent);

            state.agents.splice(index, 1, tempAgent);
          }
        }
      })
      .addCase(editAgentAction.rejected, (state, action) => {
        state.editAgentStatus = 'failed';

        if (action.payload) state.editAgentError = action.payload.message;
        else state.editAgentError = action.error.message as string;
      });

    builder
      .addCase(deleteAgentAction.pending, state => {
        state.deleteAgentStatus = 'loading';
      })
      .addCase(deleteAgentAction.fulfilled, (state, action) => {
        state.deleteAgentStatus = 'completed';
        state.deleteAgentSuccess = action.payload.message;

        if (action.payload.result) {
          const tempAgent = action.payload.result;
          const tempAgents = [...state.agents];

          const agent = tempAgents.find(agent => agent.id === tempAgent.id);

          if (agent) {
            const index = tempAgents.indexOf(agent);

            state.agents.splice(index, 1);
          }
        }
      })
      .addCase(deleteAgentAction.rejected, (state, action) => {
        state.deleteAgentStatus = 'failed';

        if (action.payload) state.deleteAgentError = action.payload.message;
        else state.deleteAgentError = action.error.message as string;
      });

    builder
      .addCase(getAgentAction.pending, state => {
        state.getAgentStatus = 'loading';
      })
      .addCase(getAgentAction.fulfilled, (state, action) => {
        state.getAgentStatus = 'completed';
        state.getAgentSuccess = action.payload.message;

        if (action.payload.result) state.agent = action.payload.result;
      })
      .addCase(getAgentAction.rejected, (state, action) => {
        state.getAgentStatus = 'failed';

        if (action.payload) state.getAgentError = action.payload.message;
        else state.getAgentError = action.error.message as string;
      });

    builder
      .addCase(getAgentsAction.pending, state => {
        state.getAgentsStatus = 'loading';
      })
      .addCase(getAgentsAction.fulfilled, (state, action) => {
        state.getAgentsStatus = 'completed';
        state.getAgentsSuccess = action.payload.message;

        if (action.payload.results) state.agents = action.payload.results;
      })
      .addCase(getAgentsAction.rejected, (state, action) => {
        state.getAgentsStatus = 'failed';

        if (action.payload) state.getAgentsError = action.payload.message;
        else state.getAgentsError = action.error.message as string;
      });

    builder
      .addCase(createAgentGroupAction.pending, state => {
        state.createAgentGroupStatus = 'loading';
      })
      .addCase(createAgentGroupAction.fulfilled, (state, action) => {
        state.createAgentGroupStatus = 'completed';
        state.createAgentGroupSuccess = action.payload.message;

        if (action.payload.result) {
          state.group = action.payload.result;

          state.groups.push(state.group);
        }
      })
      .addCase(createAgentGroupAction.rejected, (state, action) => {
        state.createAgentGroupStatus = 'failed';

        if (action.payload) state.createAgentGroupError = action.payload.message;
        else state.createAgentGroupError = action.error.message as string;
      });

    builder
      .addCase(editAgentGroupAction.pending, state => {
        state.editAgentGroupStatus = 'loading';
      })
      .addCase(editAgentGroupAction.fulfilled, (state, action) => {
        state.editAgentGroupStatus = 'completed';
        state.editAgentGroupSuccess = action.payload.message;

        if (action.payload.result) {
          const tempGroup = action.payload.result;
          const tempGroups = [...state.groups];

          const group = tempGroups.find(group => group.id === tempGroup.id);

          if (group) {
            const index = tempGroups.indexOf(group);

            state.groups.splice(index, 1, tempGroup);
          }
        }
      })
      .addCase(editAgentGroupAction.rejected, (state, action) => {
        state.editAgentGroupStatus = 'failed';

        if (action.payload) state.editAgentGroupError = action.payload.message;
        else state.editAgentGroupError = action.error.message as string;
      });

    builder
      .addCase(deleteAgentGroupAction.pending, state => {
        state.deleteAgentGroupStatus = 'loading';
      })
      .addCase(deleteAgentGroupAction.fulfilled, (state, action) => {
        state.deleteAgentGroupStatus = 'completed';
        state.deleteAgentGroupSuccess = action.payload.message;

        if (action.payload.result) {
          const tempGroup = action.payload.result;
          const tempGroups = [...state.groups];

          const group = tempGroups.find(group => group.id === tempGroup.id);

          if (group) {
            const index = tempGroups.indexOf(group);

            state.groups.splice(index, 1);
          }
        }
      })
      .addCase(deleteAgentGroupAction.rejected, (state, action) => {
        state.deleteAgentGroupStatus = 'failed';

        if (action.payload) state.deleteAgentGroupError = action.payload.message;
        else state.deleteAgentGroupError = action.error.message as string;
      });

    builder
      .addCase(getAgentGroupAction.pending, state => {
        state.getAgentGroupStatus = 'loading';
      })
      .addCase(getAgentGroupAction.fulfilled, (state, action) => {
        state.getAgentGroupStatus = 'completed';
        state.getAgentGroupSuccess = action.payload.message;

        if (action.payload.result) state.group = action.payload.result;
      })
      .addCase(getAgentGroupAction.rejected, (state, action) => {
        state.getAgentGroupStatus = 'failed';

        if (action.payload) state.getAgentGroupError = action.payload.message;
        else state.getAgentGroupError = action.error.message as string;
      });

    builder
      .addCase(getAgentGroupsAction.pending, state => {
        state.getAgentGroupsStatus = 'loading';
      })
      .addCase(getAgentGroupsAction.fulfilled, (state, action) => {
        state.getAgentGroupsStatus = 'completed';
        state.getAgentGroupsSuccess = action.payload.message;

        if (action.payload.results) state.groups = action.payload.results;
      })
      .addCase(getAgentGroupsAction.rejected, (state, action) => {
        state.getAgentGroupsStatus = 'failed';

        if (action.payload) state.getAgentGroupsError = action.payload.message;
        else state.getAgentGroupsError = action.error.message as string;
      });
  },
});

export const {
  resetCreateAgentStatus,
  resetGetAgentsStatus,
  resetDeleteAgentStatus,
  resetEditAgentStatus,
  resetGetAgentStatus,
  resetEditAgentGroupStatus,
  resetGetAgentGroupsStatus,
  resetGetAgentGroupStatus,
  resetDeleteAgentGroupStatus,
  resetCreateAgentGroupStatus,
} = agentManagementSlice.actions;
export default agentManagementSlice.reducer;
