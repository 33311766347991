import { createSlice } from '@reduxjs/toolkit';
import { IThunkStatus } from '@app-types';
import { IFormModel } from '@app-models';
import { createCaseFormAction, getCaseFormsAction } from '../actions/caseFormActions';

interface ICaseFormState {
  createCaseFormStatus: IThunkStatus;
  createCaseFormSuccess: string;
  createCaseFormError: string;

  getCaseFormsStatus: IThunkStatus;
  getCaseFormsSuccess: string;
  getCaseFormsError: string;

  caseForm: IFormModel | null;
  caseForms: IFormModel[];
}

const initialState: ICaseFormState = {
  createCaseFormStatus: 'idle',
  createCaseFormSuccess: '',
  createCaseFormError: '',

  getCaseFormsStatus: 'idle',
  getCaseFormsSuccess: '',
  getCaseFormsError: '',

  caseForm: null,
  caseForms: [],
};

const caseFormSlice = createSlice({
  name: 'caseForm',
  initialState,
  reducers: {
    resetCreateCaseForm(state: ICaseFormState) {
      state.createCaseFormStatus = 'idle';
      state.createCaseFormSuccess = '';
      state.createCaseFormError = '';
    },
    resetGetCaseForms(state: ICaseFormState) {
      state.getCaseFormsStatus = 'idle';
      state.getCaseFormsSuccess = '';
      state.getCaseFormsError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCaseFormAction.pending, state => {
        state.createCaseFormStatus = 'loading';
      })
      .addCase(createCaseFormAction.fulfilled, (state, action) => {
        state.createCaseFormStatus = 'completed';
        state.createCaseFormSuccess = action.payload.message;

        if (action.payload.result) state.caseForm = action.payload.result;
      })
      .addCase(createCaseFormAction.rejected, (state, action) => {
        state.createCaseFormStatus = 'failed';

        if (action.payload) state.createCaseFormError = action.payload.message;
        else state.createCaseFormError = action.error.message as string;
      });

    builder
      .addCase(getCaseFormsAction.pending, state => {
        state.getCaseFormsStatus = 'loading';
      })
      .addCase(getCaseFormsAction.fulfilled, (state, action) => {
        state.getCaseFormsStatus = 'completed';
        state.getCaseFormsSuccess = action.payload.message;

        if (action.payload.results) state.caseForms = action.payload.results;
      })
      .addCase(getCaseFormsAction.rejected, (state, action) => {
        state.getCaseFormsStatus = 'failed';

        if (action.payload) state.getCaseFormsError = action.payload.message;
        else state.getCaseFormsError = action.error.message as string;
      });
  },
});

export const { resetCreateCaseForm, resetGetCaseForms } = caseFormSlice.actions;
export default caseFormSlice.reducer;
