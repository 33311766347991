import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ICaseEmailFormSchema, ICaseFormSchema } from '@app-interfaces';
import { ICaseModel } from '@app-models';
import { Http } from '../../constants';

const CREATE_CASE = 'case:CREATE_CASE';
const EDIT_CASE = 'case:EDIT_CASE';
const DELETE_CASE = 'case:DELETE_CASE';
const GET_CASES = 'case:GET_CASES';
const SEND_EMAIL = 'case:SEND_EMAIL';
const IMPORT_CASES_ONLY = 'case:IMPORT_CASES_ONLY';
const IMPORT_NEW_AND_EXISTING_CASES = 'case:IMPORT_NEW_AND_EXISTING_CASES';
const GET_CUSTOMER_CASES_HISTORY = 'case:GET_CUSTOMER_CASES_HISTORY';
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });
export const createCaseAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, ICaseFormSchema>(
  CREATE_CASE,
  async args => {
    const response = await client.post(`${API_ROOT}/cases`, args);

    return response.data;
  },
);

type EditCaseType = {
  id: number;
  data: unknown;
};
export const editCaseAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, EditCaseType>(EDIT_CASE, async args => {
  const response = await client.patch(`${API_ROOT}/cases/${args.id}`, args.data);

  return response.data;
});

export const getCasesAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, void>(GET_CASES, async () => {
  const response = await client.get(`${API_ROOT}/cases`);

  return response.data;
});

export const deleteCaseAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, number>(DELETE_CASE, async args => {
  const response = await client.delete(`${API_ROOT}/cases/${args}`);

  return response.data;
});

export const importCasesOnlyAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, FormData>(
  IMPORT_CASES_ONLY,
  async args => {
    const response = await client.post(`${API_ROOT}/cases/import-data-only`, {
      data: args,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: Http.APPLICATION_JSON,
      },
    });

    return response.data;
  },
);

export const importNewAndExistingCasesAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, FormData>(
  IMPORT_NEW_AND_EXISTING_CASES,
  async args => {
    const response = await client.post(`${API_ROOT}/cases/import-new-and-existing-data`, {
      data: args,
      headers: {
        'Content-Type': Http.MULTI_PART_FORM_DATA,
        Accept: Http.APPLICATION_JSON,
      },
    });

    return response.data;
  },
);

type SendEmailType = {
  caseId: number;
  data: ICaseEmailFormSchema;
};

export const sendCaseEmailAction = asyncThunkWrapper<ApiResponseSuccess<string>, SendEmailType>(
  SEND_EMAIL,
  async args => {
    const { caseId, data } = args;
    const attachments = data.attachments;

    const formData = new FormData();

    if (attachments.length) {
      attachments.forEach((value, index) => {
        formData.append(`file-${index}`, value, value.name);
      });

      client.defaults.headers.post['Content-Type'] = Http.MULTI_PART_FORM_DATA;
    }

    formData.append('fields', JSON.stringify(data));

    const response = await client.post(`${API_ROOT}/cases/${caseId}/send-email`, formData);

    return response.data;
  },
);

export const getCustomerCasesAction = asyncThunkWrapper<ApiResponseSuccess<ICaseModel>, string>(
  GET_CUSTOMER_CASES_HISTORY,
  async args => {
    const response = await client.get(`${API_ROOT}/cases/customer?phone=${args}`);

    return response.data;
  },
);
