import { IThunkStatus } from '@app-types';
import { IDailySummary, IMonthlySummary } from '@app-interfaces';
import { createSlice } from '@reduxjs/toolkit';
import { getCasePropsSummaryAction, getDailySummaryAction, getMonthlySummaryAction } from '../actions/dashboardActions';

interface IDashboardState {
  getDailySummaryStatus: IThunkStatus;
  getDailySummarySuccess: string;
  getDailySummaryError: string;

  getMonthlySummaryStatus: IThunkStatus;
  getMonthlySummarySuccess: string;
  getMonthlySummaryError: string;

  getCasePropsSummaryStatus: IThunkStatus;
  getCasePropsSummarySuccess: string;
  getCasePropsSummaryError: string;

  dailySummaryData: IDailySummary | Record<string, number>;
  monthlySummaryData: Record<string, IMonthlySummary[]>;
  casePropsSummaryData: Record<string, { labels: string[]; data: number[] }>;
}

const initialState: IDashboardState = {
  getDailySummaryStatus: 'idle',
  getDailySummarySuccess: '',
  getDailySummaryError: '',
  getMonthlySummaryStatus: 'idle',
  getMonthlySummarySuccess: '',
  getMonthlySummaryError: '',
  getCasePropsSummaryStatus: 'idle',
  getCasePropsSummarySuccess: '',
  getCasePropsSummaryError: '',

  dailySummaryData: {},
  monthlySummaryData: {},
  casePropsSummaryData: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetGetDailySummaryStatus(state: IDashboardState) {
      state.getDailySummaryStatus = 'idle';
      state.getDailySummarySuccess = '';
      state.getDailySummaryError = '';
    },
    resetGetMonthlySummaryStatus(state: IDashboardState) {
      state.getMonthlySummaryStatus = 'idle';
      state.getMonthlySummarySuccess = '';
      state.getMonthlySummaryError = '';
    },
    resetGetCasePropsSummaryStatus(state: IDashboardState) {
      state.getCasePropsSummaryStatus = 'idle';
      state.getCasePropsSummarySuccess = '';
      state.getCasePropsSummaryError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDailySummaryAction.pending, state => {
        state.getDailySummaryStatus = 'loading';
      })
      .addCase(getDailySummaryAction.fulfilled, (state, action) => {
        state.getDailySummaryStatus = 'completed';
        state.getDailySummarySuccess = action.payload.message;
        if (action.payload.result) state.dailySummaryData = action.payload.result;
      })
      .addCase(getDailySummaryAction.rejected, (state, action) => {
        state.getDailySummaryStatus = 'failed';

        if (action.payload) state.getDailySummaryError = action.payload.message;
        else state.getDailySummaryError = action.error.message as string;
      });

    builder
      .addCase(getMonthlySummaryAction.pending, state => {
        state.getMonthlySummaryStatus = 'loading';
      })
      .addCase(getMonthlySummaryAction.fulfilled, (state, action) => {
        state.getMonthlySummaryStatus = 'completed';
        state.getMonthlySummarySuccess = action.payload.message;
        if (action.payload.result) state.monthlySummaryData = action.payload.result;
      })
      .addCase(getMonthlySummaryAction.rejected, (state, action) => {
        state.getMonthlySummaryStatus = 'failed';

        if (action.payload) state.getMonthlySummaryError = action.payload.message;
        else state.getMonthlySummaryError = action.error.message as string;
      });

    builder
      .addCase(getCasePropsSummaryAction.pending, state => {
        state.getCasePropsSummaryStatus = 'loading';
      })
      .addCase(getCasePropsSummaryAction.fulfilled, (state, action) => {
        state.getCasePropsSummaryStatus = 'completed';
        state.getCasePropsSummarySuccess = action.payload.message;
        if (action.payload.result) state.casePropsSummaryData = action.payload.result;
      })
      .addCase(getCasePropsSummaryAction.rejected, (state, action) => {
        state.getCasePropsSummaryStatus = 'failed';

        if (action.payload) state.getCasePropsSummaryError = action.payload.message;
        else state.getCasePropsSummaryError = action.error.message as string;
      });
  },
});

export const { resetGetDailySummaryStatus, resetGetMonthlySummaryStatus, resetGetCasePropsSummaryStatus } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
