import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, ICasePropsFormSchema } from '@app-interfaces';
import { ICasePropsModel } from '@app-models';

type EditType = {
  id: number;
  data: ICasePropsFormSchema;
};

const CREATE_CASE_TYPE = 'case:settings:CREATE_CASE_TYPE';
const EDIT_CASE_TYPE = 'case:settings:EDIT_CASE_TYPE';
const DELETE_CASE_TYPE = 'case:settings:DELETE_CASE_TYPE';
const GET_CASE_TYPE = 'case:settings:GET_CASE_TYPE';
const GET_CASE_TYPES = 'case:settings:GET_CASE_TYPES';

const CREATE_CASE_STATE = 'case:settings:CREATE_CASE_STATE';
const EDIT_CASE_STATE = 'case:settings:EDIT_CASE_STATE';
const DELETE_CASE_STATE = 'case:settings:DELETE_CASE_STATE';
const GET_CASE_STATE = 'case:settings:GET_CASE_STATE';
const GET_CASE_STATES = 'case:settings:GET_CASE_STATES';

const CREATE_CASE_STATUS = 'case:settings:CREATE_CASE_STATUS';
const EDIT_CASE_STATUS = 'case:settings:EDIT_CASE_STATUS';
const DELETE_CASE_STATUS = 'case:settings:DELETE_CASE_STATUS';
const GET_CASE_STATUS = 'case:settings:GET_CASE_STATUS';
const GET_CASE_STATUSES = 'case:settings:GET_CASE_STATUSES';

const CREATE_CASE_DISPOSITION = 'case:settings:CREATE_CASE_DISPOSITION';
const EDIT_CASE_DISPOSITION = 'case:settings:EDIT_CASE_DISPOSITION';
const DELETE_CASE_DISPOSITION = 'case:settings:DELETE_CASE_DISPOSITION';
const GET_CASE_DISPOSITION = 'case:settings:GET_CASE_DISPOSITION';
const GET_CASE_DISPOSITIONS = 'case:settings:GET_CASE_DISPOSITIONS';

const CREATE_CASE_PRIORITY = 'case:settings:CREATE_CASE_PRIORITY';
const EDIT_CASE_PRIORITY = 'case:settings:EDIT_CASE_PRIORITY';
const DELETE_CASE_PRIORITY = 'case:settings:DELETE_CASE_PRIORITY';
const GET_CASE_PRIORITY = 'case:settings:GET_CASE_PRIORITY';
const GET_CASE_PRIORITIES = 'case:settings:GET_CASE_PRIORITIES';

const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

export const createCaseTypeAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, ICasePropsFormSchema>(
  CREATE_CASE_TYPE,
  async args => {
    const response = await client.post(`${API_ROOT}/caseTypes`, args);

    return response.data;
  },
);

export const editCaseTypeAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, EditType>(
  EDIT_CASE_TYPE,
  async args => {
    const response = await client.patch(`${API_ROOT}/caseTypes/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCaseTypeAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  DELETE_CASE_TYPE,
  async args => {
    const response = await client.delete(`${API_ROOT}/caseTypes/${args}`);

    return response.data;
  },
);

export const getCaseTypeAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  GET_CASE_TYPE,
  async args => {
    const response = await client.get(`${API_ROOT}/caseTypes/${args}`);

    return response.data;
  },
);

export const getCaseTypesAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, void>(
  GET_CASE_TYPES,
  async () => {
    const response = await client.get(`${API_ROOT}/caseTypes`);

    return response.data;
  },
);

export const createCaseStateAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, ICasePropsFormSchema>(
  CREATE_CASE_STATE,
  async args => {
    const response = await client.post(`${API_ROOT}/caseStates`, args);

    return response.data;
  },
);

export const editCaseStateAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, EditType>(
  EDIT_CASE_STATE,
  async args => {
    const response = await client.patch(`${API_ROOT}/caseStates/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCaseStateAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  DELETE_CASE_STATE,
  async args => {
    const response = await client.delete(`${API_ROOT}/caseStates/${args}`);

    return response.data;
  },
);

export const getCaseStateAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  GET_CASE_STATE,
  async args => {
    const response = await client.get(`${API_ROOT}/caseStates/${args}`);

    return response.data;
  },
);

export const getCaseStatesAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, void>(
  GET_CASE_STATES,
  async () => {
    const response = await client.get(`${API_ROOT}/caseStates`);

    return response.data;
  },
);

export const createCaseStatusAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, ICasePropsFormSchema>(
  CREATE_CASE_STATUS,
  async args => {
    const response = await client.post(`${API_ROOT}/caseStatuses`, args);

    return response.data;
  },
);

export const editCaseStatusAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, EditType>(
  EDIT_CASE_STATUS,
  async args => {
    const response = await client.patch(`${API_ROOT}/caseStatuses/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCaseStatusAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  DELETE_CASE_STATUS,
  async args => {
    const response = await client.delete(`${API_ROOT}/caseStatuses/${args}`);

    return response.data;
  },
);

export const getCaseStatusAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  GET_CASE_STATUS,
  async args => {
    const response = await client.get(`${API_ROOT}/caseStatuses/${args}`);

    return response.data;
  },
);

export const getCaseStatusesAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, void>(
  GET_CASE_STATUSES,
  async () => {
    const response = await client.get(`${API_ROOT}/caseStatuses`);

    return response.data;
  },
);

export const createCaseDispositionAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, ICasePropsFormSchema>(
  CREATE_CASE_DISPOSITION,
  async args => {
    const response = await client.post(`${API_ROOT}/caseDispositions`, args);

    return response.data;
  },
);

export const editCaseDispositionAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, EditType>(
  EDIT_CASE_DISPOSITION,
  async args => {
    const response = await client.patch(`${API_ROOT}/caseDispositions/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCaseDispositionAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  DELETE_CASE_DISPOSITION,
  async args => {
    const response = await client.delete(`${API_ROOT}/caseDispositions/${args}`);

    return response.data;
  },
);

export const getCaseDispositionAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  GET_CASE_DISPOSITION,
  async args => {
    const response = await client.get(`${API_ROOT}/caseDispositions/${args}`);

    return response.data;
  },
);

export const getCaseDispositionsAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, void>(
  GET_CASE_DISPOSITIONS,
  async () => {
    const response = await client.get(`${API_ROOT}/caseDispositions`);

    return response.data;
  },
);

export const createCasePriorityAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, ICasePropsFormSchema>(
  CREATE_CASE_PRIORITY,
  async args => {
    const response = await client.post(`${API_ROOT}/casePriorities`, args);

    return response.data;
  },
);

export const editCasePriorityAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, EditType>(
  EDIT_CASE_PRIORITY,
  async args => {
    const response = await client.patch(`${API_ROOT}/casePriorities/${args.id}`, args.data);

    return response.data;
  },
);

export const deleteCasePriorityAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  DELETE_CASE_PRIORITY,
  async args => {
    const response = await client.delete(`${API_ROOT}/casePriorities/${args}`);

    return response.data;
  },
);

export const getCasePriorityAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, number>(
  GET_CASE_PRIORITY,
  async args => {
    const response = await client.get(`${API_ROOT}/casePriorities/${args}`);

    return response.data;
  },
);

export const getCasePrioritiesAction = asyncThunkWrapper<ApiResponseSuccess<ICasePropsModel>, void>(
  GET_CASE_PRIORITIES,
  async () => {
    const response = await client.get(`${API_ROOT}/casePriorities`);

    return response.data;
  },
);
