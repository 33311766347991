import React from 'react';

import { LeftSideNavType, RightSideNavType } from '@app-types';
import {
  AccountBoxOutlined,
  AccountCircle,
  AssessmentOutlined,
  AssignmentLateOutlined,
  DashboardOutlined,
  FactCheckOutlined,
  GroupOutlined,
  MailOutlined,
  SecurityOutlined,
  Settings,
  SupervisedUserCircleOutlined,
  SupportAgentOutlined,
  VerifiedUserOutlined,
} from '@mui/icons-material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import ErrorPage from '../pages/error/ErrorPage';
import PublicLayout from '../components/layout/PublicLayout';

const LoginPage = React.lazy(() => import('../pages/auth/LoginPage'));
const PrivateLayout = React.lazy(() => import('../components/layout/PrivateLayout'));

const authorities = [
  { action: 'manage', subject: 'all' },
  { action: 'manage', subject: 'agent' },
  { action: 'manage', subject: 'customer' },
];

export const leftSideNavs: LeftSideNavType[] = [
  {
    tag: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    Icon: DashboardOutlined,
    hasAnyAuthority: authorities,
  },
  { tag: 'mail', name: 'Mail', path: '/mail', Icon: MailOutlined, hasAnyAuthority: authorities },
  {
    tag: 'case',
    name: 'Case',
    path: '/cases',
    Icon: AssignmentLateOutlined,
    children: [
      { name: 'Create Case', path: '/create', Icon: null, hasAnyAuthority: authorities },
      { name: 'View Cases', path: '/view', Icon: null, hasAnyAuthority: authorities },
      { name: 'Import Cases', path: '/import', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Case Types', path: '/types', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Case States', path: '/states', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Case Statuses', path: '/statuses', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Case Dispositions', path: '/dispositions', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Case Priorities', path: '/priorities', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
    ],
    hasAnyAuthority: authorities,
  },
  {
    tag: 'auth',
    name: 'Agent',
    path: '/agent-management',
    Icon: SupportAgentOutlined,
    children: [
      { name: 'Agents', path: '/agents', Icon: SupportAgentOutlined, hasAnyAuthority: authorities.slice(0, 1) },
      {
        name: 'Agent Groups',
        path: '/agent-groups',
        Icon: SupervisedUserCircleOutlined,
        hasAnyAuthority: authorities.slice(0, 1),
      },
    ],
    hasAnyAuthority: authorities.slice(0, 1),
  },
  {
    tag: 'customers',
    name: 'Customers',
    path: '/customers',
    Icon: AccountBoxOutlined,
    hasAnyAuthority: authorities,
  },
  {
    tag: 'auth',
    name: 'Authentication',
    path: '/authentication',
    Icon: SecurityOutlined,
    children: [
      { name: 'Roles', path: '/roles', Icon: VerifiedUserOutlined, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Users', path: '/users', Icon: GroupOutlined, hasAnyAuthority: authorities.slice(0, 1) },
    ],
    hasAnyAuthority: authorities.slice(0, 1),
  },
  {
    tag: 'qualityAssurance',
    name: 'Quality Assurance',
    path: '/quality-assurance',
    Icon: FactCheckOutlined,
    children: [
      { name: 'Forms', path: '/forms', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Evaluate', path: '/evaluate', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
      { name: 'Evaluations', path: '/evaluations', Icon: null, hasAnyAuthority: authorities.slice(0, 1) },
    ],
    hasAnyAuthority: authorities.slice(0, 1),
  },
  {
    tag: 'reports',
    name: 'Reports',
    path: '/reports',
    Icon: AssessmentOutlined,
    children: [
      { name: 'Cases', path: '/cases', Icon: null, hasAnyAuthority: authorities },
      { name: 'Emails', path: '/emails', Icon: null, hasAnyAuthority: authorities },
    ],
    hasAnyAuthority: authorities,
  },
];

export const rightSideNavs: RightSideNavType[] = [
  { tag: 'profile', name: 'Profile', path: '/profile', Icon: AccountCircle, hasAnyAuthority: authorities },
  { tag: 'settings', name: 'Settings', path: '/settings', Icon: Settings, hasAnyAuthority: authorities.slice(0, 1) },
];

export const routes = [
  { name: 'Home', path: '/', Element: LoginPage, isPublic: true },
  { name: 'Admin Login', path: '/login', Element: LoginPage, isPublic: true },
  { name: 'Agent Login', path: '/agent/login', Element: LoginPage, isPublic: true },
  { name: 'Dashboard', path: '*', Element: PrivateLayout, isPublic: false },
];

export const getBreadcrumbsNameMap = () => {
  const breadcrumbNameMap: { [key: string]: string } = {};

  for (let i = 0; i < leftSideNavs.length; i++) {
    const parent = leftSideNavs[i];

    if (parent.children) {
      breadcrumbNameMap[parent.path] = parent.name.toLowerCase();

      const children = parent.children as LeftSideNavType[];

      for (let j = 0; j < children.length; j++) {
        breadcrumbNameMap[`${parent.path}${children[j].path}`] = children[j].name.toLowerCase();
      }

      continue;
    }

    breadcrumbNameMap[parent.path] = parent.name.toLowerCase();
  }

  return breadcrumbNameMap;
};

export const AppRoutesWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          if (route.isPublic) {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicLayout>
                    <route.Element />
                  </PublicLayout>
                }
              />
            );
          } else
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute>
                    <route.Element />
                  </PrivateRoute>
                }
              />
            );
        })}
        <Route path='/error' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};
