import { IThunkStatus } from '@app-types';
import { IFacebookConfigModel } from '@app-models';
import { createSlice } from '@reduxjs/toolkit';
import {
  createFacebookConfigAction,
  deleteFacebookConfigAction,
  editFacebookConfigAction,
  generateWebhookTokenAction,
  getFacebookConfigsAction,
} from '../actions/facebookConfigActions';

interface IFacebookConfigState {
  createFacebookConfigStatus: IThunkStatus;
  createFacebookConfigSuccess: string;
  createFacebookConfigError: string;

  editFacebookConfigStatus: IThunkStatus;
  editFacebookConfigSuccess: string;
  editFacebookConfigError: string;

  deleteFacebookConfigStatus: IThunkStatus;
  deleteFacebookConfigSuccess: string;
  deleteFacebookConfigError: string;

  getFacebookConfigsStatus: IThunkStatus;
  getFacebookConfigsSuccess: string;
  getFacebookConfigsError: string;

  generateWebhookTokenStatus: IThunkStatus;
  generateWebhookTokenSuccess: string;
  generateWebhookTokenError: string;

  facebookConfig: IFacebookConfigModel | null;
  facebookConfigs: IFacebookConfigModel[];

  webhookToken: string;
}

const initialState: IFacebookConfigState = {
  deleteFacebookConfigError: '',
  deleteFacebookConfigStatus: 'idle',
  deleteFacebookConfigSuccess: '',
  editFacebookConfigError: '',
  editFacebookConfigStatus: 'idle',
  editFacebookConfigSuccess: '',
  getFacebookConfigsError: '',
  getFacebookConfigsStatus: 'idle',
  getFacebookConfigsSuccess: '',
  createFacebookConfigStatus: 'idle',
  createFacebookConfigSuccess: '',
  createFacebookConfigError: '',
  generateWebhookTokenError: '',
  generateWebhookTokenStatus: 'idle',
  generateWebhookTokenSuccess: '',

  facebookConfig: null,
  facebookConfigs: [],
  webhookToken: '',
};

const facebookConfigSlice = createSlice({
  name: 'facebook:settings',
  initialState,
  reducers: {
    resetCreateFacebookConfigStatus(state: IFacebookConfigState) {
      state.createFacebookConfigStatus = 'idle';
      state.createFacebookConfigSuccess = '';
      state.createFacebookConfigSuccess = '';
    },
    resetEditFacebookConfigStatus(state: IFacebookConfigState) {
      state.editFacebookConfigStatus = 'idle';
      state.editFacebookConfigSuccess = '';
      state.editFacebookConfigSuccess = '';
    },
    resetDeleteFacebookConfigStatus(state: IFacebookConfigState) {
      state.deleteFacebookConfigStatus = 'idle';
      state.deleteFacebookConfigSuccess = '';
      state.deleteFacebookConfigSuccess = '';
    },

    resetGetFacebookConfigsStatus(state: IFacebookConfigState) {
      state.getFacebookConfigsStatus = 'idle';
      state.getFacebookConfigsSuccess = '';
      state.getFacebookConfigsSuccess = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createFacebookConfigAction.pending, state => {
        state.createFacebookConfigStatus = 'loading';
      })
      .addCase(createFacebookConfigAction.fulfilled, (state, action) => {
        state.createFacebookConfigStatus = 'completed';
        state.createFacebookConfigSuccess = action.payload.message;

        if (action.payload.result) {
          state.facebookConfig = action.payload.result;

          state.facebookConfigs.push(action.payload.result);
        }
      })
      .addCase(createFacebookConfigAction.rejected, (state, action) => {
        state.createFacebookConfigStatus = 'failed';

        if (action.payload) {
          state.createFacebookConfigError = action.payload.message;
        } else state.createFacebookConfigError = action.error.message as string;
      });

    builder
      .addCase(editFacebookConfigAction.pending, state => {
        state.editFacebookConfigStatus = 'loading';
      })
      .addCase(editFacebookConfigAction.fulfilled, (state, action) => {
        state.editFacebookConfigStatus = 'completed';
        state.editFacebookConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.facebookConfigs];

          state.facebookConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.facebookConfigs.splice(index, 1, config);
          }
        }
      })
      .addCase(editFacebookConfigAction.rejected, (state, action) => {
        state.editFacebookConfigStatus = 'failed';

        if (action.payload) {
          state.editFacebookConfigError = action.payload.message;
        } else state.editFacebookConfigError = action.error.message as string;
      });

    builder
      .addCase(deleteFacebookConfigAction.pending, state => {
        state.deleteFacebookConfigStatus = 'loading';
      })
      .addCase(deleteFacebookConfigAction.fulfilled, (state, action) => {
        state.deleteFacebookConfigStatus = 'completed';
        state.deleteFacebookConfigSuccess = action.payload.message;

        if (action.payload.result) {
          const config = action.payload.result;
          const configs = [...state.facebookConfigs];

          state.facebookConfig = config;

          const prevConfig = configs.find(item => item.id === config.id);

          if (prevConfig) {
            const index = configs.indexOf(prevConfig);

            state.facebookConfigs.splice(index, 1);
          }
        }
      })
      .addCase(deleteFacebookConfigAction.rejected, (state, action) => {
        state.deleteFacebookConfigStatus = 'failed';

        if (action.payload) {
          state.deleteFacebookConfigError = action.payload.message;
        } else state.deleteFacebookConfigError = action.error.message as string;
      });

    builder
      .addCase(getFacebookConfigsAction.pending, state => {
        state.getFacebookConfigsStatus = 'loading';
      })
      .addCase(getFacebookConfigsAction.fulfilled, (state, action) => {
        state.getFacebookConfigsStatus = 'completed';
        state.getFacebookConfigsSuccess = action.payload.message;
        state.facebookConfigs = action.payload.results as IFacebookConfigModel[];
      })
      .addCase(getFacebookConfigsAction.rejected, (state, action) => {
        state.getFacebookConfigsStatus = 'failed';

        if (action.payload) {
          state.getFacebookConfigsError = action.payload.message;
        } else state.getFacebookConfigsError = action.error.message as string;
      });

    builder
      .addCase(generateWebhookTokenAction.pending, state => {
        state.generateWebhookTokenStatus = 'loading';
      })
      .addCase(generateWebhookTokenAction.fulfilled, (state, action) => {
        state.generateWebhookTokenStatus = 'completed';
        state.generateWebhookTokenSuccess = action.payload.message;
        state.facebookConfig = action.payload.result as IFacebookConfigModel;
        state.webhookToken = state.facebookConfig.verifyToken;
      })
      .addCase(generateWebhookTokenAction.rejected, (state, action) => {
        state.generateWebhookTokenStatus = 'failed';

        if (action.payload) {
          state.generateWebhookTokenError = action.payload.message;
        } else state.generateWebhookTokenError = action.error.message as string;
      });
  },
});

export const {
  resetCreateFacebookConfigStatus,
  resetGetFacebookConfigsStatus,
  resetEditFacebookConfigStatus,
  resetDeleteFacebookConfigStatus,
} = facebookConfigSlice.actions;
export default facebookConfigSlice.reducer;
