import { createAsyncThunk } from '@reduxjs/toolkit';
import { io, Socket } from 'socket.io-client';
import { apiClient, settings } from '../../config';

const INIT_SOCKET = 'socket:INIT_SOCKET';
const SERVER_HOST = settings.api.baseURL as string;
const API_ROOT = settings.api.root;
const client = apiClient({ token: true });

const socketAction = createAsyncThunk<Socket, void>(INIT_SOCKET, (_, thunkAPI) => {
  const socket = io(SERVER_HOST);

  return new Promise(resolve => {
    socket.on('connect_error', err => {
      thunkAPI.rejectWithValue(err.message);
    });

    socket.on('connect', () => {
      client.put(`${API_ROOT}/users/update-socket-id`, {
        socketId: socket.id,
      });

      resolve(socket);
    });
  });
});

export default socketAction;
