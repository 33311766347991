import { apiClient, settings } from '../../config';
import asyncThunkWrapper from '../../helpers/asyncThunkWrapper';
import { ApiResponseSuccess, IBuildCaseFormSchema } from '@app-interfaces';
import { IFormModel } from '@app-models';

const CREATE_FORM = 'caseForm:CREATE_FORM';
const GET_FORMS = 'caseForm:GET_FORMS';
const API_ROOT = settings.api.root;

const client = apiClient({ token: true });

export const createCaseFormAction = asyncThunkWrapper<ApiResponseSuccess<IFormModel>, IBuildCaseFormSchema>(
  CREATE_FORM,
  async args => {
    const response = await client.post(`${API_ROOT}/forms`, args);

    return response.data;
  },
);

export const getCaseFormsAction = asyncThunkWrapper<ApiResponseSuccess<IFormModel>, void>(GET_FORMS, async () => {
  const response = await client.get(`${API_ROOT}/forms`);

  return response.data;
});
