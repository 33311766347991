import { IThunkStatus } from '@app-types';
import { createSlice } from '@reduxjs/toolkit';
import { ICasePropsModel } from '@app-models';
import {
  createCaseDispositionAction,
  createCasePriorityAction,
  createCaseStateAction,
  createCaseStatusAction,
  createCaseTypeAction,
  deleteCaseDispositionAction,
  deleteCasePriorityAction,
  deleteCaseStateAction,
  deleteCaseStatusAction,
  deleteCaseTypeAction,
  editCaseDispositionAction,
  editCasePriorityAction,
  editCaseStateAction,
  editCaseStatusAction,
  editCaseTypeAction,
  getCaseDispositionAction,
  getCaseDispositionsAction,
  getCasePrioritiesAction,
  getCasePriorityAction,
  getCaseStateAction,
  getCaseStatesAction,
  getCaseStatusAction,
  getCaseStatusesAction,
  getCaseTypeAction,
  getCaseTypesAction,
} from '../actions/caseSettingsActions';

interface IDisposition {
  createCaseDispositionStatus: IThunkStatus;
  createCaseDispositionSuccess: string;
  createCaseDispositionError: string;

  editCaseDispositionStatus: IThunkStatus;
  editCaseDispositionSuccess: string;
  editCaseDispositionError: string;

  deleteCaseDispositionStatus: IThunkStatus;
  deleteCaseDispositionSuccess: string;
  deleteCaseDispositionError: string;

  getCaseDispositionStatus: IThunkStatus;
  getCaseDispositionSuccess: string;
  getCaseDispositionError: string;

  getCaseDispositionsStatus: IThunkStatus;
  getCaseDispositionsSuccess: string;
  getCaseDispositionsError: string;

  caseDispositions: ICasePropsModel[];
  caseDisposition: ICasePropsModel | null;
}

interface IPriority extends IDisposition {
  createCasePriorityStatus: IThunkStatus;
  createCasePrioritySuccess: string;
  createCasePriorityError: string;

  editCasePriorityStatus: IThunkStatus;
  editCasePrioritySuccess: string;
  editCasePriorityError: string;

  deleteCasePriorityStatus: IThunkStatus;
  deleteCasePrioritySuccess: string;
  deleteCasePriorityError: string;

  getCasePriorityStatus: IThunkStatus;
  getCasePrioritySuccess: string;
  getCasePriorityError: string;

  getCasePrioritiesStatus: IThunkStatus;
  getCasePrioritiesSuccess: string;
  getCasePrioritiesError: string;

  casePriorities: ICasePropsModel[];
  casePriority: ICasePropsModel | null;
}

interface ICaseSettingsState extends IPriority {
  createCaseTypeStatus: IThunkStatus;
  createCaseTypeSuccess: string;
  createCaseTypeError: string;

  editCaseTypeStatus: IThunkStatus;
  editCaseTypeSuccess: string;
  editCaseTypeError: string;

  deleteCaseTypeStatus: IThunkStatus;
  deleteCaseTypeSuccess: string;
  deleteCaseTypeError: string;

  getCaseTypeStatus: IThunkStatus;
  getCaseTypeSuccess: string;
  getCaseTypeError: string;

  getCaseTypesStatus: IThunkStatus;
  getCaseTypesSuccess: string;
  getCaseTypesError: string;

  createCaseStateStatus: IThunkStatus;
  createCaseStateSuccess: string;
  createCaseStateError: string;

  editCaseStateStatus: IThunkStatus;
  editCaseStateSuccess: string;
  editCaseStateError: string;

  deleteCaseStateStatus: IThunkStatus;
  deleteCaseStateSuccess: string;
  deleteCaseStateError: string;

  getCaseStateStatus: IThunkStatus;
  getCaseStateSuccess: string;
  getCaseStateError: string;

  getCaseStatesStatus: IThunkStatus;
  getCaseStatesSuccess: string;
  getCaseStatesError: string;

  createCaseStatusStatus: IThunkStatus;
  createCaseStatusSuccess: string;
  createCaseStatusError: string;

  editCaseStatusStatus: IThunkStatus;
  editCaseStatusSuccess: string;
  editCaseStatusError: string;

  deleteCaseStatusStatus: IThunkStatus;
  deleteCaseStatusSuccess: string;
  deleteCaseStatusError: string;

  getCaseStatusStatus: IThunkStatus;
  getCaseStatusSuccess: string;
  getCaseStatusError: string;

  getCaseStatusesStatus: IThunkStatus;
  getCaseStatusesSuccess: string;
  getCaseStatusesError: string;

  caseTypes: ICasePropsModel[];
  caseType: ICasePropsModel | null;
  caseStates: ICasePropsModel[];
  caseState: ICasePropsModel | null;
  caseStatuses: ICasePropsModel[];
  caseStatus: ICasePropsModel | null;
}

const initialState: ICaseSettingsState = {
  caseState: null,
  caseStates: [],
  caseStatus: null,
  caseStatuses: [],
  caseType: null,
  caseTypes: [],
  caseDisposition: null,
  caseDispositions: [],
  casePriority: null,
  casePriorities: [],
  createCaseStateError: '',
  createCaseStateStatus: 'idle',
  createCaseStateSuccess: '',
  createCaseStatusError: '',
  createCaseStatusStatus: 'idle',
  createCaseStatusSuccess: '',
  createCaseTypeError: '',
  createCaseTypeStatus: 'idle',
  createCaseTypeSuccess: '',
  deleteCaseStateError: '',
  deleteCaseStateStatus: 'idle',
  deleteCaseStateSuccess: '',
  deleteCaseStatusError: '',
  deleteCaseStatusStatus: 'idle',
  deleteCaseStatusSuccess: '',
  deleteCaseTypeError: '',
  deleteCaseTypeStatus: 'idle',
  deleteCaseTypeSuccess: '',
  editCaseStateError: '',
  editCaseStateStatus: 'idle',
  editCaseStateSuccess: '',
  editCaseStatusError: '',
  editCaseStatusStatus: 'idle',
  editCaseStatusSuccess: '',
  editCaseTypeError: '',
  editCaseTypeStatus: 'idle',
  editCaseTypeSuccess: '',
  getCaseStateError: '',
  getCaseStateStatus: 'idle',
  getCaseStateSuccess: '',
  getCaseStatesError: '',
  getCaseStatesStatus: 'idle',
  getCaseStatesSuccess: '',
  getCaseStatusError: '',
  getCaseStatusStatus: 'idle',
  getCaseStatusSuccess: '',
  getCaseStatusesError: '',
  getCaseStatusesStatus: 'idle',
  getCaseStatusesSuccess: '',
  getCaseTypeError: '',
  getCaseTypeStatus: 'idle',
  getCaseTypeSuccess: '',
  getCaseTypesError: '',
  getCaseTypesStatus: 'idle',
  getCaseTypesSuccess: '',
  createCaseDispositionError: '',
  createCaseDispositionStatus: 'idle',
  createCaseDispositionSuccess: '',
  createCasePriorityError: '',
  createCasePriorityStatus: 'idle',
  createCasePrioritySuccess: '',
  deleteCaseDispositionError: '',
  deleteCaseDispositionStatus: 'idle',
  deleteCaseDispositionSuccess: '',
  deleteCasePriorityError: '',
  deleteCasePriorityStatus: 'idle',
  deleteCasePrioritySuccess: '',
  editCaseDispositionError: '',
  editCaseDispositionStatus: 'idle',
  editCaseDispositionSuccess: '',
  editCasePriorityError: '',
  editCasePriorityStatus: 'idle',
  editCasePrioritySuccess: '',
  getCaseDispositionError: '',
  getCaseDispositionStatus: 'idle',
  getCaseDispositionSuccess: '',
  getCaseDispositionsError: '',
  getCaseDispositionsStatus: 'idle',
  getCaseDispositionsSuccess: '',
  getCasePrioritiesError: '',
  getCasePrioritiesStatus: 'idle',
  getCasePrioritiesSuccess: '',
  getCasePriorityError: '',
  getCasePriorityStatus: 'idle',
  getCasePrioritySuccess: '',
};

const caseSettingsSlice = createSlice({
  name: 'case:settings',
  initialState,
  reducers: {
    resetCaseTypeStatus(state: ICaseSettingsState) {
      state.createCaseTypeStatus = 'idle';
      state.editCaseTypeStatus = 'idle';
      state.deleteCaseTypeStatus = 'idle';
      state.getCaseTypeStatus = 'idle';
      state.getCaseTypesStatus = 'idle';
      state.createCaseTypeSuccess = '';
      state.editCaseTypeSuccess = '';
      state.deleteCaseTypeSuccess = '';
      state.getCaseTypeSuccess = '';
      state.getCaseTypesSuccess = '';
      state.createCaseTypeError = '';
      state.editCaseTypeError = '';
      state.deleteCaseTypeError = '';
      state.getCaseTypeError = '';
      state.getCaseTypesError = '';
    },
    resetCaseStateStatus(state: ICaseSettingsState) {
      state.createCaseStateStatus = 'idle';
      state.editCaseStateStatus = 'idle';
      state.deleteCaseStateStatus = 'idle';
      state.getCaseStateStatus = 'idle';
      state.getCaseStatesStatus = 'idle';
      state.createCaseStateSuccess = '';
      state.editCaseStateSuccess = '';
      state.deleteCaseStateSuccess = '';
      state.getCaseStateSuccess = '';
      state.getCaseStatesSuccess = '';
      state.createCaseStateError = '';
      state.editCaseStateError = '';
      state.deleteCaseStateError = '';
      state.getCaseStateError = '';
      state.getCaseStatesError = '';
    },
    resetCaseStatusStatus(state: ICaseSettingsState) {
      state.createCaseStatusStatus = 'idle';
      state.editCaseStatusStatus = 'idle';
      state.deleteCaseStatusStatus = 'idle';
      state.getCaseStatusStatus = 'idle';
      state.getCaseStatusesStatus = 'idle';
      state.createCaseStatusSuccess = '';
      state.editCaseStatusSuccess = '';
      state.deleteCaseStatusSuccess = '';
      state.getCaseStatusSuccess = '';
      state.getCaseStatusesSuccess = '';
      state.createCaseStatusError = '';
      state.editCaseStatusError = '';
      state.deleteCaseStatusError = '';
      state.getCaseStatusError = '';
      state.getCaseStatusesError = '';
    },
    resetCaseDispositionStatus(state: ICaseSettingsState) {
      state.createCaseDispositionStatus = 'idle';
      state.editCaseDispositionStatus = 'idle';
      state.deleteCaseDispositionStatus = 'idle';
      state.getCaseDispositionStatus = 'idle';
      state.getCaseDispositionsStatus = 'idle';
      state.createCaseDispositionSuccess = '';
      state.editCaseDispositionSuccess = '';
      state.deleteCaseDispositionSuccess = '';
      state.getCaseDispositionSuccess = '';
      state.getCaseDispositionsSuccess = '';
      state.createCaseDispositionError = '';
      state.editCaseDispositionError = '';
      state.deleteCaseDispositionError = '';
      state.getCaseDispositionError = '';
      state.getCaseDispositionsError = '';
    },
    resetCasePriorityStatus(state: ICaseSettingsState) {
      state.createCasePriorityStatus = 'idle';
      state.editCasePriorityStatus = 'idle';
      state.deleteCasePriorityStatus = 'idle';
      state.getCasePriorityStatus = 'idle';
      state.getCasePrioritiesStatus = 'idle';
      state.createCasePrioritySuccess = '';
      state.editCasePrioritySuccess = '';
      state.deleteCasePrioritySuccess = '';
      state.getCasePrioritySuccess = '';
      state.getCasePrioritiesSuccess = '';
      state.createCasePriorityError = '';
      state.editCasePriorityError = '';
      state.deleteCasePriorityError = '';
      state.getCasePriorityError = '';
      state.getCasePrioritiesError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCaseTypeAction.pending, state => {
        state.createCaseTypeStatus = 'loading';
      })
      .addCase(createCaseTypeAction.fulfilled, (state, action) => {
        state.createCaseTypeStatus = 'completed';
        state.createCaseTypeSuccess = action.payload.message;

        if (action.payload.result) {
          const caseType = action.payload.result;

          state.caseType = caseType;
          state.caseTypes.push(caseType);
        }
      })
      .addCase(createCaseTypeAction.rejected, (state, action) => {
        state.createCaseTypeStatus = 'completed';

        if (action.payload) state.createCaseTypeError = action.payload.message;
        else state.createCaseTypeError = action.error.message as string;
      })
      .addCase(editCaseTypeAction.pending, state => {
        state.editCaseTypeStatus = 'loading';
      })
      .addCase(editCaseTypeAction.fulfilled, (state, action) => {
        state.editCaseTypeStatus = 'completed';
        state.editCaseTypeSuccess = action.payload.message;

        if (action.payload.result) {
          const caseType = action.payload.result;
          const caseTypes = [...state.caseTypes];

          const prevType = caseTypes.find(type => type.id === caseType.id);

          if (prevType) {
            const index = caseTypes.indexOf(prevType);
            caseTypes[index] = caseType;

            state.caseTypes = caseTypes;
          }
        }
      })
      .addCase(editCaseTypeAction.rejected, (state, action) => {
        state.editCaseTypeStatus = 'completed';

        if (action.payload) state.editCaseTypeError = action.payload.message;
        else state.editCaseTypeError = action.error.message as string;
      })
      .addCase(deleteCaseTypeAction.pending, state => {
        state.deleteCaseTypeStatus = 'loading';
      })
      .addCase(deleteCaseTypeAction.fulfilled, (state, action) => {
        state.deleteCaseTypeStatus = 'completed';
        state.deleteCaseTypeSuccess = action.payload.message;

        if (action.payload.result) {
          const caseType = action.payload.result;
          const caseTypes = [...state.caseTypes];

          const prevType = caseTypes.find(type => type.id === caseType.id);

          if (prevType) {
            const index = caseTypes.indexOf(prevType);

            state.caseTypes.splice(index, 1);
          }
        }
      })
      .addCase(deleteCaseTypeAction.rejected, (state, action) => {
        state.deleteCaseTypeStatus = 'completed';

        if (action.payload) state.deleteCaseTypeError = action.payload.message;
        else state.deleteCaseTypeError = action.error.message as string;
      })
      .addCase(getCaseTypeAction.pending, state => {
        state.getCaseTypeStatus = 'loading';
      })
      .addCase(getCaseTypeAction.fulfilled, (state, action) => {
        state.getCaseTypeStatus = 'completed';
        state.getCaseTypeSuccess = action.payload.message;

        if (action.payload.result) {
          state.caseType = action.payload.result;
        }
      })
      .addCase(getCaseTypeAction.rejected, (state, action) => {
        state.getCaseTypeStatus = 'completed';

        if (action.payload) state.getCaseTypeError = action.payload.message;
        else state.getCaseTypeError = action.error.message as string;
      })
      .addCase(getCaseTypesAction.pending, state => {
        state.getCaseTypesStatus = 'loading';
      })
      .addCase(getCaseTypesAction.fulfilled, (state, action) => {
        state.getCaseTypesStatus = 'completed';
        state.getCaseTypesSuccess = action.payload.message;

        if (action.payload.results) {
          state.caseTypes = action.payload.results;
        }
      })
      .addCase(getCaseTypesAction.rejected, (state, action) => {
        state.getCaseTypesStatus = 'completed';

        if (action.payload) state.getCaseTypesError = action.payload.message;
        else state.getCaseTypesError = action.error.message as string;
      });

    builder
      .addCase(createCaseStateAction.pending, state => {
        state.createCaseStateStatus = 'loading';
      })
      .addCase(createCaseStateAction.fulfilled, (state, action) => {
        state.createCaseStateStatus = 'completed';
        state.createCaseStateSuccess = action.payload.message;

        if (action.payload.result) {
          const caseState = action.payload.result;

          state.caseState = caseState;
          state.caseStates.push(caseState);
        }
      })
      .addCase(createCaseStateAction.rejected, (state, action) => {
        state.createCaseStateStatus = 'completed';

        if (action.payload) state.createCaseStateError = action.payload.message;
        else state.createCaseStateError = action.error.message as string;
      })
      .addCase(editCaseStateAction.pending, state => {
        state.editCaseStateStatus = 'loading';
      })
      .addCase(editCaseStateAction.fulfilled, (state, action) => {
        state.editCaseStateStatus = 'completed';
        state.editCaseStateSuccess = action.payload.message;

        if (action.payload.result) {
          const caseState = action.payload.result;
          const caseStates = [...state.caseStates];

          const prevType = caseStates.find(state => state.id === caseState.id);

          if (prevType) {
            const index = caseStates.indexOf(prevType);
            caseStates[index] = caseState;

            state.caseStates = caseStates;
          }
        }
      })
      .addCase(editCaseStateAction.rejected, (state, action) => {
        state.editCaseStateStatus = 'completed';

        if (action.payload) state.editCaseStateError = action.payload.message;
        else state.editCaseStateError = action.error.message as string;
      })
      .addCase(deleteCaseStateAction.pending, state => {
        state.deleteCaseStateStatus = 'loading';
      })
      .addCase(deleteCaseStateAction.fulfilled, (state, action) => {
        state.deleteCaseStateStatus = 'completed';
        state.deleteCaseStateSuccess = action.payload.message;

        if (action.payload.result) {
          const caseState = action.payload.result;
          const caseStates = [...state.caseStates];

          const prevType = caseStates.find(state => state.id === caseState.id);

          if (prevType) {
            const index = caseStates.indexOf(prevType);

            state.caseStates.splice(index, 1);
          }
        }
      })
      .addCase(deleteCaseStateAction.rejected, (state, action) => {
        state.deleteCaseStateStatus = 'completed';

        if (action.payload) state.deleteCaseStateError = action.payload.message;
        else state.deleteCaseStateError = action.error.message as string;
      })
      .addCase(getCaseStateAction.pending, state => {
        state.getCaseStateStatus = 'loading';
      })
      .addCase(getCaseStateAction.fulfilled, (state, action) => {
        state.getCaseStateStatus = 'completed';
        state.getCaseStateSuccess = action.payload.message;

        if (action.payload.result) {
          state.caseState = action.payload.result;
        }
      })
      .addCase(getCaseStateAction.rejected, (state, action) => {
        state.getCaseStateStatus = 'completed';

        if (action.payload) state.getCaseStateError = action.payload.message;
        else state.getCaseStateError = action.error.message as string;
      })
      .addCase(getCaseStatesAction.pending, state => {
        state.getCaseStatesStatus = 'loading';
      })
      .addCase(getCaseStatesAction.fulfilled, (state, action) => {
        state.getCaseStatesStatus = 'completed';
        state.getCaseStatesSuccess = action.payload.message;

        if (action.payload.results) {
          state.caseStates = action.payload.results;
        }
      })
      .addCase(getCaseStatesAction.rejected, (state, action) => {
        state.getCaseStatesStatus = 'completed';

        if (action.payload) state.getCaseStatesError = action.payload.message;
        else state.getCaseStatesError = action.error.message as string;
      });

    builder
      .addCase(createCaseStatusAction.pending, state => {
        state.createCaseStatusStatus = 'loading';
      })
      .addCase(createCaseStatusAction.fulfilled, (state, action) => {
        state.createCaseStatusStatus = 'completed';
        state.createCaseStatusSuccess = action.payload.message;

        if (action.payload.result) {
          const caseStatus = action.payload.result;

          state.caseStatus = caseStatus;
          state.caseStatuses.push(caseStatus);
        }
      })
      .addCase(createCaseStatusAction.rejected, (state, action) => {
        state.createCaseStatusStatus = 'completed';

        if (action.payload) state.createCaseStatusError = action.payload.message;
        else state.createCaseStatusError = action.error.message as string;
      })
      .addCase(editCaseStatusAction.pending, state => {
        state.editCaseStatusStatus = 'loading';
      })
      .addCase(editCaseStatusAction.fulfilled, (state, action) => {
        state.editCaseStatusStatus = 'completed';
        state.editCaseStatusSuccess = action.payload.message;

        if (action.payload.result) {
          const caseStatus = action.payload.result;
          const caseStatuses = [...state.caseStatuses];

          const prevType = caseStatuses.find(status => status.id === caseStatus.id);

          if (prevType) {
            const index = caseStatuses.indexOf(prevType);
            caseStatuses[index] = caseStatus;

            state.caseStatuses = caseStatuses;
          }
        }
      })
      .addCase(editCaseStatusAction.rejected, (state, action) => {
        state.editCaseStatusStatus = 'completed';

        if (action.payload) state.editCaseStatusError = action.payload.message;
        else state.editCaseStatusError = action.error.message as string;
      })
      .addCase(deleteCaseStatusAction.pending, state => {
        state.deleteCaseStatusStatus = 'loading';
      })
      .addCase(deleteCaseStatusAction.fulfilled, (state, action) => {
        state.deleteCaseStatusStatus = 'completed';
        state.deleteCaseStatusSuccess = action.payload.message;

        if (action.payload.result) {
          const caseStatus = action.payload.result;
          const caseStatuses = [...state.caseStatuses];

          const prevType = caseStatuses.find(status => status.id === caseStatus.id);

          if (prevType) {
            const index = caseStatuses.indexOf(prevType);

            state.caseStatuses.splice(index, 1);
          }
        }
      })
      .addCase(deleteCaseStatusAction.rejected, (state, action) => {
        state.deleteCaseStatusStatus = 'completed';

        if (action.payload) state.deleteCaseStatusError = action.payload.message;
        else state.deleteCaseStatusError = action.error.message as string;
      })
      .addCase(getCaseStatusAction.pending, state => {
        state.getCaseStatusStatus = 'loading';
      })
      .addCase(getCaseStatusAction.fulfilled, (state, action) => {
        state.getCaseStatusStatus = 'completed';
        state.getCaseStatusSuccess = action.payload.message;

        if (action.payload.result) {
          state.caseStatus = action.payload.result;
        }
      })
      .addCase(getCaseStatusAction.rejected, (state, action) => {
        state.getCaseStatusStatus = 'completed';

        if (action.payload) state.getCaseStatusError = action.payload.message;
        else state.getCaseStatusError = action.error.message as string;
      })
      .addCase(getCaseStatusesAction.pending, state => {
        state.getCaseStatusesStatus = 'loading';
      })
      .addCase(getCaseStatusesAction.fulfilled, (state, action) => {
        state.getCaseStatusesStatus = 'completed';
        state.getCaseStatusesSuccess = action.payload.message;

        if (action.payload.results) {
          state.caseStatuses = action.payload.results;
        }
      })
      .addCase(getCaseStatusesAction.rejected, (state, action) => {
        state.getCaseStatusesStatus = 'completed';

        if (action.payload) state.getCaseStatusesError = action.payload.message;
        else state.getCaseStatusesError = action.error.message as string;
      });

    builder
      .addCase(createCaseDispositionAction.pending, state => {
        state.createCaseDispositionStatus = 'loading';
      })
      .addCase(createCaseDispositionAction.fulfilled, (state, action) => {
        state.createCaseDispositionStatus = 'completed';
        state.createCaseDispositionSuccess = action.payload.message;

        if (action.payload.result) {
          const caseDisposition = action.payload.result;

          state.caseDisposition = caseDisposition;
          state.caseDispositions.push(caseDisposition);
        }
      })
      .addCase(createCaseDispositionAction.rejected, (state, action) => {
        state.createCaseDispositionStatus = 'completed';

        if (action.payload) state.createCaseDispositionError = action.payload.message;
        else state.createCaseDispositionError = action.error.message as string;
      })
      .addCase(editCaseDispositionAction.pending, state => {
        state.editCaseDispositionStatus = 'loading';
      })
      .addCase(editCaseDispositionAction.fulfilled, (state, action) => {
        state.editCaseDispositionStatus = 'completed';
        state.editCaseDispositionSuccess = action.payload.message;

        if (action.payload.result) {
          const caseDisposition = action.payload.result;
          const caseDispositions = [...state.caseDispositions];

          const prevType = caseDispositions.find(type => type.id === caseDisposition.id);

          if (prevType) {
            const index = caseDispositions.indexOf(prevType);
            caseDispositions[index] = caseDisposition;

            state.caseDispositions = caseDispositions;
          }
        }
      })
      .addCase(editCaseDispositionAction.rejected, (state, action) => {
        state.editCaseDispositionStatus = 'completed';

        if (action.payload) state.editCaseDispositionError = action.payload.message;
        else state.editCaseDispositionError = action.error.message as string;
      })
      .addCase(deleteCaseDispositionAction.pending, state => {
        state.deleteCaseDispositionStatus = 'loading';
      })
      .addCase(deleteCaseDispositionAction.fulfilled, (state, action) => {
        state.deleteCaseDispositionStatus = 'completed';
        state.deleteCaseDispositionSuccess = action.payload.message;

        if (action.payload.result) {
          const caseDisposition = action.payload.result;
          const caseDispositions = [...state.caseDispositions];

          const prevType = caseDispositions.find(type => type.id === caseDisposition.id);

          if (prevType) {
            const index = caseDispositions.indexOf(prevType);

            state.caseDispositions.splice(index, 1);
          }
        }
      })
      .addCase(deleteCaseDispositionAction.rejected, (state, action) => {
        state.deleteCaseDispositionStatus = 'completed';

        if (action.payload) state.deleteCaseDispositionError = action.payload.message;
        else state.deleteCaseDispositionError = action.error.message as string;
      })
      .addCase(getCaseDispositionAction.pending, state => {
        state.getCaseDispositionStatus = 'loading';
      })
      .addCase(getCaseDispositionAction.fulfilled, (state, action) => {
        state.getCaseDispositionStatus = 'completed';
        state.getCaseDispositionSuccess = action.payload.message;

        if (action.payload.result) {
          state.caseDisposition = action.payload.result;
        }
      })
      .addCase(getCaseDispositionAction.rejected, (state, action) => {
        state.getCaseDispositionStatus = 'completed';

        if (action.payload) state.getCaseDispositionError = action.payload.message;
        else state.getCaseDispositionError = action.error.message as string;
      })
      .addCase(getCaseDispositionsAction.pending, state => {
        state.getCaseDispositionsStatus = 'loading';
      })
      .addCase(getCaseDispositionsAction.fulfilled, (state, action) => {
        state.getCaseDispositionsStatus = 'completed';
        state.getCaseDispositionsSuccess = action.payload.message;

        if (action.payload.results) {
          state.caseDispositions = action.payload.results;
        }
      })
      .addCase(getCaseDispositionsAction.rejected, (state, action) => {
        state.getCaseDispositionsStatus = 'completed';

        if (action.payload) state.getCaseDispositionsError = action.payload.message;
        else state.getCaseDispositionsError = action.error.message as string;
      });

    builder
      .addCase(createCasePriorityAction.pending, state => {
        state.createCasePriorityStatus = 'loading';
      })
      .addCase(createCasePriorityAction.fulfilled, (state, action) => {
        state.createCasePriorityStatus = 'completed';
        state.createCasePrioritySuccess = action.payload.message;

        if (action.payload.result) {
          const casePriority = action.payload.result;

          state.casePriority = casePriority;
          state.casePriorities.push(casePriority);
        }
      })
      .addCase(createCasePriorityAction.rejected, (state, action) => {
        state.createCasePriorityStatus = 'completed';

        if (action.payload) state.createCasePriorityError = action.payload.message;
        else state.createCasePriorityError = action.error.message as string;
      })
      .addCase(editCasePriorityAction.pending, state => {
        state.editCasePriorityStatus = 'loading';
      })
      .addCase(editCasePriorityAction.fulfilled, (state, action) => {
        state.editCasePriorityStatus = 'completed';
        state.editCasePrioritySuccess = action.payload.message;

        if (action.payload.result) {
          const casePriority = action.payload.result;
          const casePriorities = [...state.casePriorities];

          const prevType = casePriorities.find(type => type.id === casePriority.id);

          if (prevType) {
            const index = casePriorities.indexOf(prevType);
            casePriorities[index] = casePriority;

            state.casePriorities = casePriorities;
          }
        }
      })
      .addCase(editCasePriorityAction.rejected, (state, action) => {
        state.editCasePriorityStatus = 'completed';

        if (action.payload) state.editCasePriorityError = action.payload.message;
        else state.editCasePriorityError = action.error.message as string;
      })
      .addCase(deleteCasePriorityAction.pending, state => {
        state.deleteCasePriorityStatus = 'loading';
      })
      .addCase(deleteCasePriorityAction.fulfilled, (state, action) => {
        state.deleteCasePriorityStatus = 'completed';
        state.deleteCasePrioritySuccess = action.payload.message;

        if (action.payload.result) {
          const casePriority = action.payload.result;
          const casePriorities = [...state.casePriorities];

          const prevType = casePriorities.find(type => type.id === casePriority.id);

          if (prevType) {
            const index = casePriorities.indexOf(prevType);

            state.casePriorities.splice(index, 1);
          }
        }
      })
      .addCase(deleteCasePriorityAction.rejected, (state, action) => {
        state.deleteCasePriorityStatus = 'completed';

        if (action.payload) state.deleteCasePriorityError = action.payload.message;
        else state.deleteCasePriorityError = action.error.message as string;
      })
      .addCase(getCasePriorityAction.pending, state => {
        state.getCasePriorityStatus = 'loading';
      })
      .addCase(getCasePriorityAction.fulfilled, (state, action) => {
        state.getCasePriorityStatus = 'completed';
        state.getCasePrioritySuccess = action.payload.message;

        if (action.payload.result) {
          state.casePriority = action.payload.result;
        }
      })
      .addCase(getCasePriorityAction.rejected, (state, action) => {
        state.getCasePriorityStatus = 'completed';

        if (action.payload) state.getCasePriorityError = action.payload.message;
        else state.getCasePriorityError = action.error.message as string;
      })
      .addCase(getCasePrioritiesAction.pending, state => {
        state.getCasePrioritiesStatus = 'loading';
      })
      .addCase(getCasePrioritiesAction.fulfilled, (state, action) => {
        state.getCasePrioritiesStatus = 'completed';
        state.getCasePrioritiesSuccess = action.payload.message;

        if (action.payload.results) {
          state.casePriorities = action.payload.results;
        }
      })
      .addCase(getCasePrioritiesAction.rejected, (state, action) => {
        state.getCasePrioritiesStatus = 'completed';

        if (action.payload) state.getCasePrioritiesError = action.payload.message;
        else state.getCasePrioritiesError = action.error.message as string;
      });
  },
});

export const {
  resetCaseTypeStatus,
  resetCaseStatusStatus,
  resetCaseStateStatus,
  resetCasePriorityStatus,
  resetCaseDispositionStatus,
} = caseSettingsSlice.actions;
export default caseSettingsSlice.reducer;
