import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiResponseError } from '@app-interfaces';
import { apiClient } from '../config';
import { AxiosClientOptionType } from '@app-types';
import { Axios } from 'axios';

export default function asyncThunkWrapper<Returned, Args>(
  prefix: string,
  handler: (args: Args, axiosClient: (arg?: AxiosClientOptionType) => Axios, thunkAPI?: any) => Promise<any>,
) {
  return createAsyncThunk<
    Returned,
    Args,
    {
      rejectValue: ApiResponseError;
    }
  >(prefix, async (args, thunkAPI) => {
    try {
      return await handler(args, apiClient, thunkAPI);
    } catch (e: any) {
      if (e.response) return thunkAPI.rejectWithValue(e.response.data);
      return Promise.reject(e);
    }
  });
}
